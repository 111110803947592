import { useAuthHeader } from "react-auth-kit";
import { onSessionExpired } from "../../js/callbacks";
import { get_api_url, is_admin } from "../../js/url";
import "./statistics_area.scss";

import React, { useEffect, useState } from 'react'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


// ---
// Statistics form
// ---

export function StatisticsArea({isAuthenticated, project}) {

  const [statistics, setStatistics] = useState(null);
  const readAuthHeader = useAuthHeader();

  useEffect(() => {
    if (isAuthenticated) {
      loadStatistics();

    }
  }, [isAuthenticated, project])
  
  function loadStatistics() {       
      const api_url = get_api_url();
      const api_endpoint = `${api_url}/stats`;
      var url = `${api_endpoint}?project=${project}`;
      
      fetch(url, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': readAuthHeader(),  // TODO: check why this is called differently here
          },
      })
      .then((response) => {        
          if (response.status === 200) {
              response.json().then((data) => {
                  setStatistics(data);
                  console.log(data);
              })
          } else if (response.status === 422) {
              console.log("error while loading user data (422)")
              onSessionExpired();
          } else if (response.status === 401) {
              console.log("error while loading user data (402)")
          } else {
              console.log("cannot load stats");
              // TODO: show error
          }
      })
      .catch((e) => {
          console.log("cannot load comments");
          console.log(e);
          // TODO: show error
      });
  }

  function hideForm() {
    hideError();
    document.getElementById("div_statistics_area").style.display = "none";
  }

  function handleKeyDown(evt) {
    if (evt.code === "Escape") {
      evt.preventDefault();
      hideForm();
    }
  }

  if (statistics == null) {
    // TODO: show empty dialog
    return <div></div>;
  }

  function adminStats() {
    if (is_admin()) {
      return (
        <div className="form_statistics_item"> 
          <h4>Admin Stats</h4>
          <pre><code>{text_stats}</code></pre>
        </div>
      )
    }
    else {
      return <div></div>;
    }
  }

  console.log(statistics)

  var stats_to_be_logged = {...statistics};
  delete stats_to_be_logged["total_12h"];
  delete stats_to_be_logged["counts_24h"];
  delete stats_to_be_logged["api_stats"];
  var text_stats = JSON.stringify(stats_to_be_logged, null, 4);

  

  return (
      <div id="div_statistics_form" className="div_statistics_form" onKeyDown={handleKeyDown}>
        <div className="form_statistics_item">
          <div className="div_statistics_form_header">
            <h2>Statistics</h2>
          </div>
        </div>
        <div className="form_statistics_item hidden" id="div_statistics_error">
          <span id="span_statistics_error" className="span_statistics_error"></span>
        </div>
        <div className="form_statistics_item">
          <h4>Last 12 hours: {statistics.total_12h} objects</h4>
        </div>
        <div className="form_statistics_item">        
          <b>Day per hour:</b>
          <BarChart
            width={700}
            height={300}
            data={statistics.counts_24h}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 5" />
            <XAxis dataKey="hour"/>
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="ai" stackId="a" fill="#0000ff" />
            <Bar dataKey="sam" stackId="a" fill="#00ffff" />
            <Bar dataKey="manual" stackId="a" fill="#ff5500" />
          </BarChart>
        </div>
        {adminStats()}
      </div>
  );
};
    
