// ---
// Initialize react
// ---

import React from 'react'
import ReactDOM from 'react-dom/client'

import { AuthProvider } from 'react-auth-kit';

import { UserApp } from './src/apps/user_app';
import { CommentApp } from './src/apps/comment_app';
import { CommunicationApp } from './src/apps/communication_app';
import { StatisticsApp } from './src/apps/statistics_app';
import { VerificationApp } from './src/apps/verification_app';


ReactDOM.createRoot(document.getElementById('div_user_app_react')).render(
    <React.StrictMode>
      <AuthProvider
            authType={"localStorage"}
            authName={"_auth"}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "https:"}
          >
        <UserApp />
        <CommentApp />
        <CommunicationApp />
        <StatisticsApp />
        <VerificationApp />
      </AuthProvider>
    </React.StrictMode>
  );