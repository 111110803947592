
import VectorSource from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {all, bbox} from 'ol/loadingstrategy.js';
import {transform} from 'ol/proj.js';
import {transformExtent} from 'ol/proj';


import { CONFIG_MAP_PROJECTION } from './config.js';
import { get_project_name, get_api_url, get_task_id } from './url.js';
import { getAuthData, isUserLoggedIn } from './../src/apps/user_app';
import { onSessionExpired } from './callbacks';


function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function _load_data(
    project_name, 
    task_id,
    data_type, 
    source, 
    extent, 
    resolution, 
    projection, 
    success, 
    failure
) {
    const t_start = performance.now();

    const extent_in_wgs84 = transformExtent(extent, "EPSG:3857", "EPSG:4326");

    const api_url = get_api_url();
    const api_endpoint = `${api_url}/query`;
    
    var url = `${api_endpoint}?` +
        `project=${project_name}&` +
        `type=${data_type}&` +
        `extent=${extent_in_wgs84.toString()}`;

    if (task_id != null) {
        url += `&task=${task_id}`;
    }

    console.log(url);

    const onError = function() {
        source.removeLoadedExtent(extent);
        failure();
    }

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }

    if (isUserLoggedIn()) {
        options.headers = {
            ...options.headers, 
            'Authorization': getAuthData(),
        }
    }
    
    fetch(url, options)
    .then((response) => {

      if (response.status === 200) {
            response.json().then(data => {
                if (isEmpty(data)) {
                    console.log("empty");
                }        
                else {
                    console.log(data['api_stats']);
                    const features = source.getFormat().readFeatures(
                        data["data"], 
                    {
                        dataProjection: 'EPSG:4326',
                        featureProjection: CONFIG_MAP_PROJECTION,
                    }
                    );
                    source.addFeatures(features);
                    success(features);
    
                    const t_end = performance.now()
    
                    console.log(`Loading took ${t_end - t_start} ms for ${url}`);
                }
        })
        .catch((e) => {
            onError();
        })
      }
      else if (response.status === 422) {
        console.log("Authentication failure (422)")
        onError();
        onSessionExpired();
      }
      else if (response.status === 401) {
        console.log("Authentication failure (401)")
        onError();
      }
      else {
        onError();
      }
    }
    )
    .catch((e) => {
        onError();
    })
}

export function setupInteractiveSource() {
        
    var source = new VectorSource({
        wrapX: false,
        format: new GeoJSON(),
        strategy: bbox, //all,
        loader: function(extent, resolution, projection, success, failure) {
    
            _load_data(
                get_project_name(),
                get_task_id(),
                "verified", 
                source, 
                extent, 
                resolution, 
                projection, 
                success, 
                failure
            );
        },
    });

    return source
}


export function setupAISource() {
    var source = new VectorSource({
        wrapX: false,
        format: new GeoJSON(),
        strategy: bbox, 
        loader: function(extent, resolution, projection, success, failure) {

            _load_data(
                get_project_name(),
                get_task_id(),
                "ai", 
                source, 
                extent, 
                resolution, 
                projection, 
                success, 
                failure
            );
        },
    });

    return source;
}


export function setupAOIsSource() {
    var source = new VectorSource({
        wrapX: false,
        format: new GeoJSON(),
        strategy: bbox, 
        loader: function(extent, resolution, projection, success, failure) {

            _load_data(
                get_project_name(),
                get_task_id(),
                "aois", 
                source, 
                extent, 
                resolution, 
                projection, 
                success, 
                failure
            );
        },
    });

    return source;
}
