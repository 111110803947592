import "./verification_app.scss";
import close_icon from "../../icons/close.png"
import prev_icon from "../../icons/left-arrow.png"
import next_icon from "../../icons/right-arrow.png"
import first_icon from "../../icons/first-arrow.png"
import last_icon from "../../icons/last-arrow.png"

import React, { useEffect, useState } from 'react'

//import { CommunicationTable } from '../components/communication_table';

import { get_api_url, get_selected_polygon_from_url, get_task_id } from '../../js/url';
import { get_project_name } from "../../js/url";

import { onSessionExpired } from '../../js/callbacks';
import { registerOnSelectedFeatureChanged } from '../../js/callbacks';
import { showInfoText } from "../../js/info";
import { getAuthData } from "./user_app";


// ---
// Navigation callbacks
// ---

var on_navigated_callback = [];

export function registerOnFeatureNavigated(func) {
    on_navigated_callback.push(func);
}

export function onFeatureNavigatedEvent(data) {
    on_navigated_callback.forEach(function (func) {
        func(data);
    })
}

// ---
// Verification app
// ---

export function VerificationApp() {
    const [project, setProject] = useState(get_project_name());
    const [task, setTask] = useState(get_task_id());
    const [data, setData] = useState({ "data": [] });
    const [feature_number, setFeatureNumber] = useState("");
    const [feature_index, setFeatureIndex] = useState("");

    useEffect(() => {
        //enableModeUnverifedData();
        registerOnSelectedFeatureChanged(function (feature_id) {
            if (feature_id !== null) {
                loadScrolling(get_selected_polygon_from_url());
                console.log("change navigate to feature index")
                console.log("changed selected feature " + feature_id);
            }
        })
    }, [project, task]);


    function loadScrolling(
        feature_id=null, 
        feature_number=null, 
        feature_index=null,
    ) {

        const api_url = get_api_url();
        const api_endpoint = `${api_url}/scroll`;
        var url = `${api_endpoint}?project=${project}`

        var mode = "";

        if (btn_verification_mode_unverified.value == "on") {
            url += `&mode=unverified`;
        } else if (btn_verification_mode_verified.value == "on") {
            url += `&mode=verified`;
        } else {
            console.log("scrolling not activated, aborting");
            return;
        }

        if (feature_number != null) {
            url += `&feature_number=${feature_number}`;
        } else if (feature_id != null) {
            url += `&feature=${feature_id}`;
        } else if (feature_index != null) {
            url += `&feature=${feature_index}`;
        }

        if (task != null) {
            url += `&task=${task}`;
        }

        console.log("request scroll for feature " + feature_id);

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthData(),
            },
        })
        .then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {

                    setData(data.data);

                    if (feature_number != null || feature_index != null) {
                        // Navigate to feature in case we requested via "goto" text field
                        onFeatureNavigatedEvent(data.data.curr)
                    }
                    else if (feature_number == null && feature_index == null && feature_id == null) {
                        onFeatureNavigatedEvent(data.data.first)
                    }

                    console.log("scrolling loaded")
                    console.log(data);
                })
            } else if (response.status === 422) {
                console.log("error while loading user data (422)")
                onSessionExpired();
            } else if (response.status === 401) {
                console.log("error while loading user data (401)")
            } else {
                console.log("cannot load scrolling");
            }
        })
        .catch((e) => {
            console.log("cannot load scrolling");
            console.log(e);
        });
    }

    function hideApp() {
        var verification_app = document.getElementById("div_verification_app");
        var verification_btn = document.getElementById("btn_verification");

        verification_app.style.display = "none";
        verification_btn.style.background = "#eeeeeeff";
        verification_btn.value = "off";
    }

    function onVerifiedButtonClicked() {
        disableModeUnverifiedData();
        enableModeVerifedData();
    }

    function onUnverifiedButtonClicked() {
        console.log("unverified clicked");

        disableModeVerifiedData();
        enableModeUnverifedData();
    }

    function enableModeVerifedData() {
        btn_verification_mode_verified.style.background = "#93c47dff";
        btn_verification_mode_verified.value = "on";

        const selected = get_selected_polygon_from_url();

        loadScrolling(selected);
    }

    function disableModeVerifiedData() {
        btn_verification_mode_verified.style.background = "#ffffff80";
        btn_verification_mode_verified.value = "off";
    }


    function enableModeUnverifedData() {
        btn_verification_mode_unverified.style.background = "#93c47dff";
        btn_verification_mode_unverified.value = "on";

        const selected = get_selected_polygon_from_url();

        loadScrolling(selected);
    }

    function disableModeUnverifiedData() {
        btn_verification_mode_unverified.style.background = "#ffffff80";
        btn_verification_mode_unverified.value = "off";
    }


    function renderToolButtonsBack() {
        if (data.prev != undefined) {
            return <div className="div_verification_app_header_button_group">
                <button className="btn_verification_app_header_button_tool"
                    title="Go to first feature"
                    onClick={
                        () => onFeatureNavigatedEvent(data.first)
                    }>
                    <img width="15px" height="15px" style={{ position: 'relative', left: -1 + 'px' }} src={first_icon} />
                </button>
                <button className="btn_verification_app_header_button_tool"
                    title="Click to navigate to previous feature"
                    onClick={
                        () => onFeatureNavigatedEvent(data.prev)
                    }>
                    <img width="15px" height="15px" style={{ position: 'relative', left: -1 + 'px' }} src={prev_icon} />
                </button>
            </div>
        } else {
            return <div className="div_verification_app_header_button_group"></div>
        }
    }

    function renderToolButtonsForward() {
        if (data.next != undefined) {
            return <div className="div_verification_app_header_button_group">
                <button className="btn_verification_app_header_button_tool"
                    title="Click to navigate to next feature"
                    onClick={
                        () => onFeatureNavigatedEvent(data.next)
                    }>
                    <img width="15px" height="15px" style={{ position: 'relative', left: 1 + 'px' }} src={next_icon} />
                </button>
                <button className="btn_verification_app_header_button_tool"
                    title="Go to last feature"
                    onClick={
                        () => onFeatureNavigatedEvent(data.last)
                    }>
                    <img width="15px" height="15px" style={{ position: 'relative', left: -1 + 'px' }} src={last_icon} />
                </button>
            </div>
        } else {
            return <div className="div_verification_app_header_button_group"></div>
        }
    }

    function renderToolButtons() {

        return (<div className="div_verification_app_header_buttons">
            {renderToolButtonsBack()}
            {renderToolButtonsForward()}
        </div>
        )
    }

    function handleGotoFeatureNumberChange(e) {
        var entered_number = e.target.value;
        var parsed_number = Number.parseInt(entered_number);

        if (entered_number.length == 0) {
            setFeatureNumber("");
        } else if (parsed_number == entered_number &&
            parsed_number > 0 &&
            parsed_number < data.total) {
            setFeatureNumber(parsed_number);
            loadScrolling(null, parsed_number);
        }
    }

    function handleGotoFeatureIndexChange(e) {
        var entered_index = e.target.value;

        if (entered_index.length == 0) {
            setFeatureIndex("");
        } else if (entered_index.length < 36) {
            setFeatureIndex(entered_index);
        } else if (entered_index.length == 36) {
            setFeatureIndex(entered_index);
            loadScrolling(null, null, entered_index);
        }
    }


    function renderFeatureProps() {

        if (data.curr != undefined) {

            const lon = data.curr.centroid[0];
            const lat = data.curr.centroid[1];
            const camera_agl_in_m = 50;

            const viewer_url = `https://www.google.com/maps/@${lat},${lon},${camera_agl_in_m}m/data=!3m1!1e3?entry=ttu`;

            return <div>
                <div className="div_verification_app_prop_major">
                    Feature # {data.curr.curr_nr} of {data.total}
                </div>

                <div className="div_verification_app_prop_minor">
                    Index: <span className="span_verification_app_prop_index"> {data.curr.index} </span>
                </div>

                {/* <div className="div_verification_app_prop_minor">
                    Centroid: {data.curr.centroid[0].toFixed(9)}, {data.curr.centroid[1].toFixed(9)}
                </div> */}

                <div className="div_verification_app_prop_link">
                    <a href={viewer_url} target="_blank">Open 3D Viewer</a>
                </div>
            </div>
        } else {
            return <div></div>
        }
    }

    return (
        <div id="div_verification_app" className="div_verification_app">
            <div className="div_verification_app_item">
                <div className="div_verification_app_header">
                    <h3>Verification</h3>
                    {renderToolButtons()}
                </div>
            </div>

            <div className="div_verification_app_item">
                <div className="div_verification_mode">
                    <button
                        id="btn_verification_mode_verified"
                        className="div_verification_mode_button"
                        onClick={() => onVerifiedButtonClicked()}
                    ><span>Verified</span></button>
                    <button
                        id="btn_verification_mode_unverified"
                        className="div_verification_mode_button"
                        onClick={() => onUnverifiedButtonClicked()}
                    ><span>Unverified</span></button>
                </div>
            </div>

            <div className="div_verification_app_item">
                <div className="div_verification_content">
                    {renderFeatureProps()}
                </div>
            </div>


            <div className="div_verification_app_item">
                <div className="div_verification_goto">
                    <span className="span_verification_goto">Go to index </span>
                    <input type="text"
                        size="30" 
                        className="input_verification_goto_by_index"
                        inputMode="numeric"
                        value={feature_index}
                        onChange={handleGotoFeatureIndexChange}
                    />
                </div>
            </div>

            <div className="div_verification_app_item">
                <div className="div_verification_goto">
                    <span className="span_verification_goto">Go to feature # </span>
                    <input type="text"
                        size="5"
                        className="input_verification_goto_by_number"
                        inputMode="numeric"
                        value={feature_number}
                        onChange={handleGotoFeatureNumberChange}
                    />
                </div>
            </div>

            <button className="btn_verification_app_header_button_close"
                title="Close verification" onClick={() => hideApp()}>
                <img width="13px" height="13px" src={close_icon} />
            </button>
        </div>
    )
}
