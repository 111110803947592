import "./communication_app.scss";
import close_icon from "../../icons/close.png"
import refresh_icon from "../../icons/refresh-arrow.png"
import prev_icon from "../../icons/left-arrow.png"
import next_icon from "../../icons/right-arrow.png"

import React, { useEffect, useState } from 'react'
import { useIsAuthenticated } from "react-auth-kit";

import { useInterval } from '../tools/interval';
import { CommunicationTable } from '../components/communication_table';

import { get_api_url, get_selected_polygon_from_url, get_task_id } from '../../js/url';
import { get_project_name } from "../../js/url";

import { onCommentsLoaded, onCommunicationUpdateRequired, onSessionExpired} from '../../js/callbacks';
import { registerOnCommunicationUpdateRequired } from '../../js/callbacks';
import { registerOnSelectedFeatureChanged } from '../../js/callbacks';

import { registerOnCommentSelected } from '../components/communication_table';
import { onCommentSelectedEvent } from '../components/communication_table';
import { registerOnCommentResolved } from '../components/communication_table';
import { showInfoText } from "../../js/info";
import { getAuthData } from "./user_app";

export function CommunicationApp() {
    const [project, setProject] = useState(get_project_name());
    const [task, setTask] = useState(get_task_id());
    const [comments, setComments] = useState({"data": []});
    const [selected_feature_id, setSelectedFeatureId] = useState(get_selected_polygon_from_url());
    
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        registerOnCommunicationUpdateRequired(function() {
            loadComments();
        });
        registerOnSelectedFeatureChanged(function(feature_id) {
            setSelectedFeatureId(feature_id);
        })
        registerOnCommentSelected(function(data) {
            console.log(data.feature_index);
            setSelectedFeatureId(data.feature_index);
        })
        registerOnCommentResolved(function(feature_id, updated_comments) {
            setComments(updated_comments);
            onCommentsLoaded(updated_comments.data);
        })
    }, [project, task]);
   
 
    useInterval(async () => {

        if (isAuthenticated()) {
            let btn_communication = document.getElementById("btn_communication");

            if (btn_communication.value != "on") {
                console.log("load comments, app deactivated")
                loadComments();
            } else {
                console.log("communication app visible, not loading data automatically")
            }
        }
    }, 5000);

    function loadComments() {       
        const api_url = get_api_url();
        const api_endpoint = `${api_url}/comment/list`;
        var url = `${api_endpoint}?project=${project}`;

        if (task != null) {
            url += `&task=${task}`;
        }
        
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthData(),
            },
        })
        .then((response) => {        
            if (response.status === 200) {
                response.json().then((data) => {
                    setComments(data);
                    onCommentsLoaded(data.data);
                })
            } else if (response.status === 422) {
                console.log("Authentication failure (422)")
                onSessionExpired();
            } else if (response.status === 401) {
                console.log("Authentication failure (401)")
            } else {
                console.log("cannot load comments");
            }
        })
        .catch((e) => {
            console.log("cannot load comments");
            console.log(e);
        });
    }

    function hideApp() {
        var communication_app = document.getElementById("div_communication_app");
        var communication_btn = document.getElementById("btn_communication");

        communication_app.style.display = "none";    
        communication_btn.style.background = "#eeeeeeff";
        communication_btn.value = "off";
    }

    function renderToolButtons() {

        if (comments.data.length >= 2) {
            var prev_comment = 0;
            var next_comment = 0;

            var selected_id = 0
            for (var i = 0; i < comments.data.length; ++i) {
                if (comments.data[i].feature_index == selected_feature_id) {
                    selected_id = i;
                }
            }

            prev_comment = comments.data[(selected_id + comments.data.length - 1) % comments.data.length];
            next_comment = comments.data[(selected_id + 1) % comments.data.length];
            
            return (<div className="div_communication_app_header_buttons">
                        <button className="btn_communication_app_header_button_tool" 
                                title="Click to highlight previous comment" 
                                onClick={
                                    () => onCommentSelectedEvent(prev_comment)
                                }>                            
                            <img width="15px" height="15px" style={{position: 'relative', left: -1 + 'px'}} src={prev_icon}/>
                        </button>
                        <button className="btn_communication_app_header_button_tool" 
                                title="Click to highlight next comment" 
                                onClick={
                                    () => onCommentSelectedEvent(next_comment)
                                }>                            
                            <img width="15px" height="15px" style={{position: 'relative', left: 1 + 'px'}} src={next_icon}/>
                        </button>
                        <button className="btn_communication_app_header_button_tool" 
                                title="Click to load new comments" onClick={() => onCommunicationUpdateRequired()}>                            
                            <img width="15px" height="15px" src={refresh_icon}/>
                        </button>
                    </div>
            )
        } else {
            return (
                <div className="div_communication_app_header_buttons">
                    <button className="btn_communication_app_header_button_tool" 
                            title="Click to load new comments" onClick={() => onCommunicationUpdateRequired()}>                            
                        <img width="15px" height="15px" src={refresh_icon}/>
                    </button>
                </div>
            )
        }
    }

    return (
        <div id="div_communication_app" className="div_communication_app">
            <div className="div_communication_app_item">
                <div className="div_communication_app_header">
                    <h3>{comments.data.length} {comments.data.length == 1 ? "Comment" : "Comments"}</h3>
                    {renderToolButtons()}
                </div>
            </div>
            <div className="div_communication_app_item">
                <CommunicationTable project={project} comments={comments} selected_feature_id={selected_feature_id}/>
            </div>

            <button className="btn_communication_app_header_button_close"
                                title="Close comments" onClick={() => hideApp()}>
                <img width="13px" height="13px" src={close_icon}/>
            </button>
        </div>
    )
}
