// ---
// CSS Imports 
// ---

import './index.scss';

// ---
// Package imports
// ---

import React from 'react'

// UUID

import { v4 as uuid } from 'uuid';

// OpenLayer

import GeoJSON from 'ol/format/GeoJSON.js';
import { getWidth } from 'ol/extent.js';
import { toLonLat, transformExtent } from 'ol/proj';

import { inAndOut } from 'ol/easing';
import { DragBox, Draw, Modify, Select, Snap } from 'ol/interaction.js';
import { Attribution } from 'ol/control.js';
import { platformModifierKeyOnly, noModifierKeys, click, singleClick, primaryAction } from 'ol/events/condition.js';
import { VectorImage } from 'ol/layer.js';
import { LinearRing } from 'ol/geom.js';

// OpenLayer Extensions
import SnapGuide from 'ol-ext/interaction/SnapGuides';
import CopyPaste from 'ol-ext/interaction/CopyPaste';


// ---
// Local imports
// ---

// JS

import { MaskPredictor } from './js/predictor.js'
import { setupInteractiveSource, setupAISource, setupAOIsSource } from './js/source';
import { 
  setupInteractiveLayer, 
  setupAILayer, 
  setupOSMLayer, 
  setupOrthoLayer, 
  setupBevOrthoLayer, 
  setupWFSLayer, 
  setupCIRLayer,
  setupWMTSLayer, 
  setupTileDebugLayer, 
  setupAOIsLayer,  
} from './js/layer';
import { setupAITileLayer, setupInteractiveTileLayer } from './js/layer';
import { setupLayers, setupMap } from './js/map';

import { showInfoText, hideInfoText } from './js/info';
import { is_admin, get_project_name, is_task_id_set, get_task_id, is_viewer, get_geometry_server_url, is_buildings } from './js/url';
import { set_selected_polygon_to_url, get_selected_polygon_from_url } from './js/url';
import { get_api_url } from './js/url';
import { setupTask } from './js/task';
import { ProgressBar } from './js/progress_bar';
import { retry, sleep } from './js/retry';

import { StateVerification } from './js/app';
import { AppData } from './js/app';
import { STYLE_POLYGON_TOOL, STYLE_SELECT_TOOL, STYLE_MAGIC_TOOL } from './js/style';

import { isUserLoggedIn, getUserData, getAuthData, onLogoutEvent } from './src/apps/user_app';
import { registerOnLoginEvent, registerOnLogoutEvent } from './src/apps/user_app';
import { isAoisAllowed, isExportAllowed } from './js/roles.js';

import { save_current_state, reset_user_state } from './js/url';
import { registerOnComment } from './src/components/comment_form';
import { registerOnCommentResolved, registerOnCommentSelected } from './src/components/communication_table';
import { onSelectedFeatureChanged, onSessionExpired, registerOnCommentsLoaded } from './js/callbacks';
import { onCommunicationUpdateRequired } from './js/callbacks';

import TranslateCustom from './js/interactions/translate.js';
import DrawCustom from './js/interactions/draw.js';
import { registerOnFeatureNavigated } from './src/apps/verification_app.jsx';
import ModifyCustom from './js/interactions/modify.js';
import { noModifierKeysEvt } from './js/keyboard.js';
import { remove } from 'ol/array.js';


var app_data = new AppData();

// ---
// Set first state to starting
// --- 

updateVerificationState(StateVerification.STATE_VERIFICATION_STARTING);

// --- 
// Set page title
// ---

if (is_viewer()) {
  document.title = "N Vision | Map Viewer"
} else {
  document.title = "N Vision | Map Editor"
}

// ---
// Unique ID generator
// ---

function uid() {
  return uuid();
}

// ---
// Query to check if logged in
//  (dirty hack because login state is stored in React and not accessible)
// ---

function isAuthenticated() {
  return isUserLoggedIn();
}

// ---
// Task related items
//


// ---
// Load task if given
// ---

var task_geometry = null

function isTaskingActivated() {
  return is_task_id_set();
}

function isCoordinateWithinTask(coordinate) {
  if (task_geometry == null) {
    // no not available yet
    return false;
  } else {
    // check if coordinate is within task
    return task_geometry.intersectsCoordinate(coordinate);
  }
}

function onTaskSetup(geometry) {
  task_geometry = geometry;
}

// ---
// Mask predictor
// ---

var mask_predictor = new MaskPredictor();
mask_predictor.set_project_name(get_project_name());
mask_predictor.on_error = maskPredictorError;
mask_predictor.on_success = maskPredictorSuccess;

// --- 
// Setup AI layers
// ---

const ai_source = setupAISource();
const ai_layer = setupAILayer(ai_source);

// Deactivate tile layer for now
//const ai_tile_layer = setupAITileLayer();

// ---
// Setup interactive layers
// ---

const interactive_source = setupInteractiveSource();
const interactive_layer = setupInteractiveLayer(interactive_source);

// Deactivate tile layer for now
//const interactive_tile_layer = setupInteractiveTileLayer();

// ---
// Setup AOIs layer
// ---

const aois_source = setupAOIsSource();
const aois_layer = setupAOIsLayer(aois_source);

// ---
// Setup OSM layer
// ---

// Deactivated for now
//const osm_layer = setupOSMLayer();

// ---
// Tile Debug Layer
// ---

//const tile_debug_layer = setupTileDebugLayer();
//overlay_layers.push(tile_debug_layer)

// ---
// Austrian buildings cadastre
// ---

const bev_buildings_layer = setupWFSLayer("austria_buildings_20230912");

// ---
// CIR image
// ---

const austria_cir_ortho_layer = setupCIRLayer();


// ---
// Land Vorarlberg Ortho 2022
// ---


// const land_vorarlberg_wmts_server_url = "http://vogis.cnv.at/mapserver/mapserv?map=i_luftbilder_r_wms.map&version=1.3.0&"
// const land_vorarlberg_wmts_layer_name = "ef2022_10cm"
// const land_vorarlberg_wmts_layer_title = "Vorarlberg - Ortho 2022"

// const vorarlberg_ortho = setupWMTSLayer(
//   land_vorarlberg_wmts_server_url,
//   land_vorarlberg_wmts_layer_name,
//   land_vorarlberg_wmts_layer_title,
// )

// ---
// Create list of overlay layers
// ---

var overlay_layers = [
  //interactive_tile_layer,
  //ai_tile_layer,
  bev_buildings_layer,
  aois_layer,
  ai_layer,
  interactive_layer,
]

// ---
// Setup ortho layer
// ---

const ortho_layer = setupOrthoLayer();
var bev_ortho_layer = setupBevOrthoLayer();

var base_layers = [
  ortho_layer,
  //vorarlberg_ortho,
  bev_ortho_layer,
  austria_cir_ortho_layer,
]

// ---
// Setup map element
// ---

const map = setupMap();
setupLayers(map, overlay_layers, base_layers)

// ---
// Event handlers for map 
// ---

map.on('click', function (evt) {

  if (progress_bar.isVisible() && progress_bar.isDone()) {
    progress_bar.setVisible(false);
    return
  }

  if (tool_active === "magic") {
    handleClick(evt, 1);
  }
  else if (tool_active === "polygon" &&
    isPolygonDrawingStarted() &&
    evt.originalEvent.altKey === true
  ) {
    tool_polygon.removeLastPoint();

    if (isPolygonDrawingStarted()) {
      // Check if polygon drawing is still active after removing point
      tool_polygon.modifyDrawing_(evt.coordinate);
    } else {
      feature_cancel();
    }
  }
});

map.on('contextmenu', function (evt) {

  // Disable context menu
  evt.preventDefault()

  if (progress_bar.isVisible() && progress_bar.isDone()) {
    progress_bar.setVisible(false);
    return
  }

  if (tool_active === "magic") {
    handleClick(evt, 0);
  }
});


// import { STYLE_HOVER_FEATURE } from './js/style'; 

// let selected = null;

// map.on('pointermove', function (e) {

//   if (selected !== null) {
//     var is_selected = false;
//     tool_select.getFeatures().forEach(function (selected_feature) {
//       if (selected.getId() == selected_feature.getId()) {
//         is_selected = true;
//       }
//     })

//     if (is_selected == false) {
//       selected.setStyle(undefined);
//       selected = null;
//     } else {
//       selected.setStyle(undefined);
//       selected = null;
//     }
//   }

//   map.forEachFeatureAtPixel(e.pixel, function (f) {

//     var is_selected = false;

//     tool_select.getFeatures().forEach(function (selected_feature) {
//       if (f.getId() == selected_feature.getId()) {
//         is_selected = true;
//       }
//     })

//     if (is_selected == false) {
//       selected = f;
//       f.setStyle(STYLE_HOVER_FEATURE);
//       return true;
//     } else {
//       f.setStyle(STYLE_HOVER_FEATURE);
//       return true;
//     }

//   });
// });

map.once('loadstart', function () {
  console.log("load start")
  console.log("application starting")

  //if (isApplicationStarting()) {      
  document.querySelector("body").style.visibility = "visible";

  if (is_viewer() == false) {
    if (isAuthenticated() == false) {
      updateVerificationState(StateVerification.STATE_VERIFICATION_WAIT_FOR_SIGNIN);
    } else {
      updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
    }
  } else {
    btn_mode.style.background = "#eeeeeeff";
    btn_mode.style.display = "none";
  }

  //}
});

map.on('loadend', function (e) {
  console.log('load end')
  selectGivenFeature();
})

map.on('movestart', function (e) {
  console.log("move start");
})

var current_zoom = map.getView().getZoom();

map.on('moveend', function (e) {

  console.log("move end")

  if (isVerificationEnabled()) {
    var new_zoom = map.getView().getZoom();

    if (current_zoom != new_zoom) {

      console.log('zoom end, new zoom: ' + new_zoom);
      current_zoom = new_zoom;

      switch (app_data.getStateVerification()) {
        case StateVerification.STATE_VERIFICATION_ENABLED:
          if (new_zoom > 17) {
            updateVerificationState(StateVerification.STATE_VERIFICATION_READY)
          }
          break;
        case StateVerification.STATE_VERIFICATION_READY:
          if (new_zoom <= 17) {
            updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
          }
          break;
        default:
          break;
      }
    }
  }

  selectGivenFeature();
});


// ---
// Function to remove layer
// ---

export function removeLayer(map, layer_name) {

  var layers_to_remove = [];

  map.getLayers().forEach(function (layer) {
      if (layer.get('name') != undefined && layer.get('name') === layer_name) {
          layers_to_remove.push(layer);
      }
  });

  var len = layers_to_remove.length;

  for(var i = 0; i < len; i++) {
      map.removeLayer(layers_to_remove[i]);
  }
}


// ---
// Click events callbacks
// ---

function handleClick(evt, button) {

  if (isVerificationAllowed() == false) {
    return;
  }

  console.log("handle click");

  if (tool_select.getFeatures().getLength() >= 1 && mask_predictor._current_feature === null) {
    // ignore
  }
  else if (mask_predictor._current_feature === null) {
    console.log("No feature selected");

    // No feature selected

    if (button === 0) {
      // Right click

      console.log("Right click and no feature selected, abort.");
    }
    else if (button === 1) {
      // Left click
      const taskingRequirementsFulfilled = isTaskingActivated() && isCoordinateWithinTask(evt.coordinate);

      if (noModifierKeys(evt) && (isTaskingActivated() == false || taskingRequirementsFulfilled)) {

        const layers = [
          ai_layer,
          interactive_layer,
        ]

        const layer_filter = function (layer) {
          return layers.includes(layer);
        }

        if (map.hasFeatureAtPixel(evt.pixel, { layerFilter: layer_filter }) === false) {
          // No feature at actual position

          console.log("Left click, no feature selected and no feature at actual position, send request");

          // Create a new object because ...
          //  - left click
          //  - no existing feature here
          //  - no feature selected

          handleMagicClick(evt, button);
        }
      }
    }
  }
  else if (mask_predictor._current_feature != null) {
    // Feature selected

    handleMagicClick(evt, button);

    // if ((isFeatureAtPixel(map, mask_predictor._current_feature, evt.pixel) === true) || 
    //     (button === 0) || 
    //     (map.hasFeatureAtPixel(evt.pixel) === false)
    //     )
    // {
    //   handleMagicClick(evt, button);
    // } 
  }
}

function handleMagicClick(evt, button) {

  if (mask_predictor._pending_requests == 0) {
    hideActionButtons();
    tool_select.style_ = STYLE_MAGIC_TOOL;
    tool_modify.setActive(false);
    tool_select.setActive(false);
  }

  var lonlat = toLonLat(evt.coordinate);
  mask_predictor.add_point(lonlat, button);
  mask_predictor.send_request();
}

function maskPredictorError(msg) {
  showInfoText(msg);
  feature_cancel();
}

function maskPredictorSuccess(data) {

  if (mask_predictor._current_feature != null) {

    // Remove previous feature

    interactive_source.removeFeature(mask_predictor._current_feature);
    tool_select.getFeatures().remove(mask_predictor._current_feature);
    mask_predictor._current_feature = null;
  }

  // Load new feature

  mask_predictor._current_feature = interactive_source.getFormat().readFeature(
    data,
    {
      dataProjection: 'EPSG:4326',
      featureProjection: map.getView().getProjection(),
    }
  );

  mask_predictor._current_feature.setProperties({
    "input_points": mask_predictor._input_points,
    "input_labels": mask_predictor._input_labels,
    "source": "sam",
  })

  interactive_source.addFeature(mask_predictor._current_feature);
  tool_select.getFeatures().push(mask_predictor._current_feature)

  if (mask_predictor._pending_requests == 0) {
    hideInfoText();
    showActionButtons();
  }
}

function handleSelect(selected_items) {

  console.log("handle select")
  console.log(selected_items)

  if (selected_items.length > 0) {
    showActionButtons();
  } else {
    hideActionButtons();
  }

  if ((selected_items.length == 1) &&
    (selected_items.at(0).getId() != undefined)) {
    const feature = selected_items.at(0);
    set_selected_polygon_to_url(feature.getId());
    onSelectedFeatureChanged(feature.getId());
  } else {
    set_selected_polygon_to_url(null);
    onSelectedFeatureChanged(null);
  }
}

// ---
// Handle feature actions
// ---

var progress_bar = new ProgressBar();

// Feature approve 

async function send_update(feature) {

  // send update to backend

  var format = new GeoJSON();
  var geojson = format.writeFeatureObject(
    feature, {
    'dataProjection': 'EPSG:4326',
    'featureProjection': 'EPSG:3857',
  });

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/add`;

  console.log("send update")

  retry(() => fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(geojson)
  }))
    .then(response => {

      if (response.status === 200) {
        console.log("add successfully sent")

        hideInfoText();
        progress_bar.succeeded(1);

      } else if (response.status === 422) {
        console.log("Authentication failure (422)")
        progress_bar.failed(1)
        onSessionExpired();
      } else if (response.status === 401) {
        console.log("Authentication failure (401)")
        progress_bar.failed(1)
      }
      else {
        console.log("response failure")
        console.log(response)
        showInfoText("Connection failure, please retry ...")
        progress_bar.failed(1)

      }

    })
    .catch((e) => {
      console.log(e)
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      console.log("network failure, cannot communicate with backend.")

      showInfoText("Connection failure, please retry ...");
      progress_bar.failed(1)
    })
    .finally(() => {

      if (progress_bar.isDone()) {
        // TODO: setActive is here only called in case all items succeeded
        //       => improve error handling
        //       => also, in case of an failed approved, features are drawn greeen still

        tool_select.style_ = STYLE_SELECT_TOOL;
        tool_modify.setActive(true);
        tool_select.setActive(true);
      }
      console.log("add finally event")
    });

}

// Feature approve 
async function send_delete(feature) {

  if (feature.getId() === undefined) {
    console.log("Undefined feature id => skip")
    return;
  }

  // send update to backend

  var body = {
    "id": feature.getId()
  }

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/delete`;

  retry(() => fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(body)
  }))
    .then(response => {

      if (response.status === 200) {
        console.log("delete successfully sent")

        hideInfoText();
        progress_bar.succeeded(1);

      } else if (response.status === 422) {
        console.log("Authentication failure (422)")
        progress_bar.failed(1);
        onSessionExpired();
      } else if (response.status === 401) {
        console.log("Authentication failure (401)")
        progress_bar.failed(1)
      }
      else {
        console.log("response failure")
        console.log(response)

        showInfoText("Connection failure, please retry ...")
        progress_bar.failed(1);
      }

    })
    .catch((e) => {
      console.log(e)
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      console.log("network failure, cannot communicate with backend.")

      showInfoText("Connection failure, please retry ...");
      progress_bar.failed(1)
    })
    .finally(() => {
      console.log('finally reached')
      if (progress_bar.isDone()) {
        tool_select.style_ = STYLE_SELECT_TOOL;
        tool_modify.setActive(true);
        tool_select.setActive(true);
      }
    });
}


async function send_comment(feature) {

  // send comment to backend

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/comment/add`;

  console.log("send comment")

  var user_data = getUserData();

  var body = {
    "user_id": user_data.id,
    "feature_index": feature.getId(),
    "comment": feature.get("comment"),
    "state": "open",
  }

  retry(() => fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(body)
  }))
    .then(response => {

      if (response.status === 200) {
        console.log("comment successfully sent")

        hideInfoText();
        progress_bar.succeeded(1);

      } else if (response.status === 422) {
        console.log("Authentication failure (422)")
        progress_bar.failed(1);
        onSessionExpired();
      } else if (response.status === 401) {
        console.log("Authentication failure (401)")
        progress_bar.failed(1)
      }
      else {
        console.log("response failure")
        console.log(response)

        showInfoText("Connection failure, please retry ...")
        progress_bar.failed(1);
      }

    })
    .catch((e) => {
      console.log(e)
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      console.log("network failure, cannot communicate with backend.")
      showInfoText("Connection failure, please retry ...");

      progress_bar.failed(1)
    })
    .finally(() => {

      if (progress_bar.isDone()) {
        // TODO: do we really need this (?) => answer: see /add api call
        tool_select.style_ = STYLE_SELECT_TOOL;
        tool_modify.setActive(true);
        tool_select.setActive(true);
      }
    });
}

function feature_approve(unselect = true) {
  console.log("approve feature, num selected features: " + tool_select.getFeatures().getLength());

  if (mask_predictor._pending_requests > 0) {
    console.log("Cannot approve, pending requests ...");
    console.log(mask_predictor._pending_requests)
    return;
  }

  if (tool_select.getFeatures().getLength() == 0 && isPolygonDrawingStarted() == false) {
    console.log("cannot approve feature, no feature selected");
    return;
  }

  tool_modify.setActive(false);
  tool_select.setActive(false);

  if (tool_active === "magic") {
    mask_predictor.reset();
  }
  else if (tool_active === "polygon") {
    tool_polygon.finishDrawing();
  }

  // send updates to backend

  progress_bar.setup(
    tool_select.getFeatures().getLength(),
    function () {
      updateVerificationState(StateVerification.STATE_VERIFICATION_BUSY);
    },
    function () {
      updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
    }
  );

  tool_select.getFeatures().forEach(function (feature) {

    if (ai_source.hasFeature(feature)) {
      // TODO: set source already at backend (?)
      feature.set("source", "ai");
      interactive_source.addFeature(feature);
      ai_source.removeFeature(feature);
    }

    if (aois_source.hasFeature(feature)) {
      console.log("feature in aoi")
      feature.set("layer", "aois")
    } else {
      console.log("feature not in aoi")
    }

    if (feature.getId() === undefined) {
      feature.setId(uid());
      console.log(`assign new id ${feature.getId()}`);
    }

    if (feature.get("project") === undefined) {
      feature.set("project", get_project_name());
    }
  })

  var response = process_in_batches(
    send_update,
    tool_select.getFeatures().getArray(),
    50,
    5
  );

  if (unselect) {
    tool_select.getFeatures().clear();
    hideActionButtons();
    set_selected_polygon_to_url(null);
    onSelectedFeatureChanged(null);
    console.log("unselected after approve")
  }
}

function feature_classify() {
  console.log("classify ...")
}

async function process_in_batches(
  func,
  data_array,
  delayInterval,
  batchSize
) {

  const remaining = [...data_array];
  const responses = [];

  while (remaining.length !== 0) {
    console.log("process batch ...")
    const batch = remaining.splice(0, batchSize);

    const [batchResponses] = await Promise.all([
      Promise.all(batch.map((item) => func(item))),
      sleep(delayInterval),
    ]);

    responses.push(...batchResponses);
  }

  return responses;
};

// Feature delete 

function feature_delete() {
  console.log("delete feature");

  if (mask_predictor._pending_requests > 0) {
    console.log("Cannot delete, pending requests ...");
    return;
  }

  let selected_features = tool_select.getFeatures();

  if (selected_features.getLength() > 0) {

    console.log("disable verification")
    progress_bar.setup(
      selected_features.getLength(),
      function () {
        updateVerificationState(StateVerification.STATE_VERIFICATION_BUSY);
      },
      function () {
        updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
      }
    );

    selected_features.forEach(function (feature) {
      if (ai_source.hasFeature(feature)) {
        ai_source.removeFeature(feature);
      }
      if (interactive_source.hasFeature(feature)) {
        interactive_source.removeFeature(feature);
      }
      if (aois_source.hasFeature(feature)) {
        aois_source.removeFeature(feature)
      }
    });

    var response = process_in_batches(
      send_delete,
      selected_features.getArray(),
      50,
      5
    );
  }

  feature_cancel();
}


function feature_regularize(regularization_type) {

  console.log("regularize feature, num selected features: " + tool_select.getFeatures().getLength());

  if (mask_predictor._pending_requests > 0) {
    console.log("Cannot regularize, pending requests ...");
    return;
  }

  if (tool_select.getFeatures().getLength() == 0 && isPolygonDrawingStarted() == false) {
    console.log("cannot regularize feature, no feature selected");
    return;
  }

  tool_modify.setActive(false);
  tool_select.setActive(false);

  // send updates to backend

  progress_bar.setup(
    tool_select.getFeatures().getLength(),
    function () {
      updateVerificationState(StateVerification.STATE_VERIFICATION_BUSY);
    },
    function () {
      updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
    }
  );

  if (regularization_type == "ortho") {
    var response = process_in_batches(
      request_ortho,
      tool_select.getFeatures().getArray(),
      50,
      5
    );
  } else if (regularization_type == "circle") {
    var response = process_in_batches(
      request_circle,
      tool_select.getFeatures().getArray(),
      50,
      5
    );
  }
}

function request_ortho(feature) {

  var format = new GeoJSON();
  var geojson = format.writeFeatureObject(
    feature, {
    'dataProjection': 'EPSG:4326',
    'featureProjection': 'EPSG:3857',
  });

  const api_url = get_geometry_server_url();
  const api_endpoint = `${api_url}/ortho`;

  console.log("send ortho request")

  retry(() => fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(geojson)
  }))
    .then(response => {

      if (response.status === 200) {
        console.log("ortho request successfully sent")

        hideInfoText();
        progress_bar.succeeded(1);

        response.json()
          .then(data => {
            console.log(data)

            console.log("ortho received")
            console.log(data["geometry"]);

            var updated_feature = interactive_source.getFormat().readFeature(
              data["geometry"],
              {
                dataProjection: 'EPSG:4326',
                featureProjection: map.getView().getProjection(),
              })

            feature.getGeometry().setCoordinates(updated_feature.getGeometry().getCoordinates());
          })
          .catch((e) => {
            console.log(e);
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            console.log("network failure, cannot communicate with backend.")

            showInfoText("Connection failure, please retry ...");
            progress_bar.failed(1)
          });

      } else if (response.status === 422) {
        console.log("Authentication failure (422)")
        progress_bar.failed(1)
        onSessionExpired();
      } else if (response.status === 401) {
        console.log("Authentication failure (401)")
        progress_bar.failed(1)
      }
      else {
        console.log("response failure")
        console.log(response)
        showInfoText("Connection failure, please retry ...")
        progress_bar.failed(1)
      }

    })
    .catch((e) => {
      console.log(e)
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      console.log("network failure, cannot communicate with backend.")

      showInfoText("Connection failure, please retry ...");
      progress_bar.failed(1)
    })
    .finally(() => {

      if (progress_bar.isDone()) {
        // TODO: setActive is here only called in case all items succeeded
        //       => improve error handling
        //       => also, in case of an failed approved, features are drawn greeen still
        tool_select.style_ = STYLE_SELECT_TOOL;
        tool_modify.setActive(true);
        tool_select.setActive(true);
      }
      console.log("add finally event")
    });
}

function request_circle(feature) {

  var format = new GeoJSON();
  var geojson = format.writeFeatureObject(
    feature, {
    'dataProjection': 'EPSG:4326',
    'featureProjection': 'EPSG:3857',
  });

  const api_url = get_geometry_server_url();
  const api_endpoint = `${api_url}/circle`;

  console.log("send ortho request")

  retry(() => fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(geojson)
  }))
    .then(response => {

      if (response.status === 200) {
        console.log("ortho request successfully sent")

        hideInfoText();
        progress_bar.succeeded(1);

        response.json()
          .then(data => {
            console.log(data)

            console.log("ortho received")
            console.log(data["geometry"]);

            var updated_feature = interactive_source.getFormat().readFeature(
              data["geometry"],
              {
                dataProjection: 'EPSG:4326',
                featureProjection: map.getView().getProjection(),
              })

            feature.getGeometry().setCoordinates(updated_feature.getGeometry().getCoordinates());
          })
          .catch((e) => {
            console.log(e);
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            console.log("network failure, cannot communicate with backend.")

            showInfoText("Connection failure, please retry ...");
            progress_bar.failed(1)
          });

      } else if (response.status === 422) {
        console.log("Authentication failure (422)")
        progress_bar.failed(1)
        onSessionExpired();
      } else if (response.status === 401) {
        console.log("Authentication failure (401)")
        progress_bar.failed(1)
      }
      else {
        console.log("response failure")
        console.log(response)
        showInfoText("Connection failure, please retry ...")
        progress_bar.failed(1)
      }

    })
    .catch((e) => {
      console.log(e)
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      console.log("network failure, cannot communicate with backend.")

      showInfoText("Connection failure, please retry ...");
      progress_bar.failed(1)
    })
    .finally(() => {

      if (progress_bar.isDone()) {
        // TODO: setActive is here only called in case all items succeeded
        //       => improve error handling
        //       => also, in case of an failed approved, features are drawn greeen still
        tool_select.style_ = STYLE_SELECT_TOOL;
        tool_modify.setActive(true);
        tool_select.setActive(true);
      }
      console.log("add finally event")
    });
}

function feature_comment(comment, unselect = true) {

  feature_approve(false);

  console.log("comment feature, num selected features: " + tool_select.getFeatures().getLength());

  if (mask_predictor._pending_requests > 0) {
    console.log("Cannot comment, pending requests ...");
    return;
  }

  if (tool_select.getFeatures().getLength() == 0 && isPolygonDrawingStarted() == false) {
    console.log("cannot comment feature, no feature selected");
    return;
  }

  tool_modify.setActive(false);
  tool_select.setActive(false);

  tool_select.getFeatures().forEach(function (feature) {
    feature.set("comment", comment);
  })

  // send updates to backend

  progress_bar.setup(
    tool_select.getFeatures().getLength(),
    function () {
      updateVerificationState(StateVerification.STATE_VERIFICATION_BUSY);
    },
    function () {
      updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
    }
  );

  var response = process_in_batches(
    send_comment,
    tool_select.getFeatures().getArray(),
    50,
    5
  );

  if (unselect) {
    hideActionButtons();
    tool_select.getFeatures().clear();
    set_selected_polygon_to_url(null);
    console.log("unselected after comment")
  }
}

function feature_cancel(unselect = true) {

  if (mask_predictor._pending_requests > 0) {
    console.log("Cannot cancel, pending requests ...");
    return;
  }

  if (tool_active === "magic") {
    interactive_source.removeFeature(mask_predictor._current_feature);
    tool_select.getFeatures().remove(mask_predictor._current_feature);
    mask_predictor.reset();
  }
  else if (tool_active === "polygon") {
    tool_polygon.abortDrawing();
    tool_snap_guide.clearGuides();

    tool_select.getFeatures().forEach(function (feature) {
      if (feature.getId() === undefined) {
        if (interactive_source.hasFeature(feature)) {
          interactive_source.removeFeature(feature);
        }
        if (aois_source.hasFeature(feature)) {
          aois_source.removeFeature(feature);
        }
      }
    })
  }

  tool_select.style_ = STYLE_SELECT_TOOL;
  tool_modify.setActive(true);
  tool_select.setActive(true);

  if (unselect == true) {
    tool_select.getFeatures().clear();
    set_selected_polygon_to_url(null);
    onSelectedFeatureChanged(null);
    hideActionButtons();
  }
}

// ---
// Animate view to given extent
// --- 

function animateToExtent(extent) {

  const pad = 200;

  const size = map.getSize();
  const width = size[0];
  const height = size[1];

  var margin_factor = 2

  const project_name = get_project_name();
  if (project_name.startsWith("cuj_")) {
    margin_factor = 12
  }

  map.getView().fit(
    transformExtent(
      extent,
      'EPSG:4326',
      'EPSG:3857'
    ), {
    duration: 250,
    size: [width / margin_factor, height / margin_factor],
    maxZoom: 21.0,
    //padding: [pad, pad, pad, pad],
  }
  );
}

// ---
// Select given feature
// ---

function selectGivenFeature() {

  if (isVerificationAllowed() == false) {
    return;
  }

  if (mask_predictor._pending_requests > 0) {
    return;
  }

  console.log("highlight selected feature")
  const selected_id = get_selected_polygon_from_url();
  console.log(selected_id)

  if (selected_id != null) {
    // TODO: update selection only if not already selected !!
    // TODO: cleanup select handling => have one function which activates action buttons

    // TODO: run this function only after map has zoomed to region and feature has been selected, otherwise feature might not be loaded yet and not selected properly

    if (interactive_source.getFeatureById(selected_id) != null) {
      tool_select.getFeatures().clear();
      const feature = interactive_source.getFeatureById(selected_id);
      tool_select.getFeatures().push(feature);
    } else if (ai_source.getFeatureById(selected_id) != null) {
      tool_select.getFeatures().clear();
      const feature = ai_source.getFeatureById(selected_id);
      tool_select.getFeatures().push(feature);
    } else if (aois_source.getFeatureById(selected_id) != null) {
      // This is not working because feature will never be shown unless the feature is loaded before this function is called (same issue as todo above)
      tool_select.getFeatures().clear();
      if (isAoisAllowed()) {
        showAoisButton()
        const feature = aois_source.getFeatureById(selected_id);
        tool_select.getFeatures().push(feature);
      }
    }

    //onSelectedFeatureChanged(selected_id);
  }

  if (tool_select.getFeatures().getLength() >= 1) {
    showActionButtons();
  }
}

/*
import intersect from '@turf/intersect';
import booleanIntersects from '@turf/boolean-intersects';
import area from '@turf/area';

function removeOverlappingAIFeatuers(feature1) {

  var format = new GeoJSON();

  var geometry1 = feature1.getGeometry();
  var extent1 = geometry1.getExtent();
  var geojson1 = format.writeFeatureObject(feature1, {
    decimals: 8
  });

  ai_source.forEachFeatureInExtent(extent1, function(feature2) {
    var geojson2 = format.writeFeatureObject(feature2);
    
    if (booleanIntersects(geojson1, geojson2)) {
      var intersection = intersect(geojson1, geojson2);
      var overlap = area(intersection) / area(geojson2);
      if (overlap > 0.1) {
        ai_source.removeFeature(feature2);
      }
    }
  })
}
*/

// ---
// Event listener for keyboard
// ---

document.addEventListener('keydown', function (evt) {

  if (progress_bar.isProgressing()) {
    return;
  } else if (progress_bar.isVisible() && progress_bar.isDone()) {
    progress_bar.setVisible(false);
    return
  }

  // Ignore keydown while typing

  if (evt.target.tagName === "INPUT") {
    return;
  } else if (evt.target.tagName === "TEXTAREA") {
    return;
  }

  if (evt.code == 'Space' && isVerificationAllowed()) {
    feature_approve();
  } else if (evt.code == 'KeyO' && isVerificationAllowed()) {
    feature_regularize("ortho");
  } else if (evt.code == 'KeyP' && isVerificationAllowed()) {
    feature_regularize("circle");
  } else if (noModifierKeysEvt(evt) && evt.code == 'KeyC' && isVerificationAllowed()) {
    evt.preventDefault();
    showCommentApp();
  } else if (evt.code == 'KeyQ' && isVerificationAllowed()) {
    feature_delete();
  } else if (evt.code == 'Escape' && isVerificationAllowed()) {
    feature_cancel();
  } else if (evt.code == 'KeyE' && isVerificationAllowed() && isAoisEditable() == false) {
    if (getActiveTool() == "magic") {
      enableToolPolygon();
      setActiveTool("polygon");
    } else if (getActiveTool() == "polygon") {
      enableToolMagic();
      setActiveTool("magic");
    } else if (getActiveTool() == null) {
      enableToolMagic();
      setActiveTool("magic");
    }
  } else if (evt.code == 'KeyR') {
    toggleMode();
  } else if (evt.code == 'KeyT') {
    toggleBevOrtho();
  } else if (evt.code == 'KeyN') {
    toggleCirOrtho();
  } else if (evt.code == 'KeyB') {
    toggleBevBuildings();
  }
});

// ---
// Functions related to verification
// ---

function toggleVerificationApp() {
  if (btn_verification.value === "on") {
    hideVerificationApp();
  } else {
    showVerificationApp();
  }
}

function showVerificationApp() {
  var verification_app = document.getElementById("div_verification_app");
  verification_app.style.display = "flex";

  btn_verification.style.background = "#93c47dff";
  btn_verification.value = "on";

  //onCommunicationUpdateRequired();
}

function hideVerificationApp() {
  var verification_app = document.getElementById("div_verification_app");

  if (verification_app != null) {
    verification_app.style.display = "none";

    btn_verification.style.background = "#eeeeeeff";
    btn_verification.value = "off";
  }
}

// --- 
// Functions related to commenting
// ---

function showCommentApp() {
  var comment_area = document.getElementById("div_comment_area");
  comment_area.style.display = "flex";
  comment_area.style.pointerEvents = "all";

  var textarea_comment = document.getElementById("textarea_comment");
  textarea_comment.focus();
  textarea_comment.select();
}

// ---
// Functions related to communication
// ---

function toggleCommunicationApp() {
  if (btn_communication.value === "on") {
    hideCommunicationApp();
  } else {
    showCommunicationApp();
  }
}

function showCommunicationApp() {
  var communication_app = document.getElementById("div_communication_app");
  communication_app.style.display = "flex";

  btn_communication.style.background = "#93c47dff";
  btn_communication.value = "on";

  onCommunicationUpdateRequired();
}

function hideCommunicationApp() {
  var communication_app = document.getElementById("div_communication_app");

  if (communication_app != null) {
    communication_app.style.display = "none";

    btn_communication.style.background = "#eeeeeeff";
    btn_communication.value = "off";
  }
}

// ---
// Event listener for tool buttons
// ---

function showOverlays() {
  if (btn_auto.value == "on") {
    ai_layer.setVisible(true);
    //ai_tile_layer.setVisible(true);
  }

  if (btn_aois.value == "on") {
    aois_layer.setVisible(true);
  }

  interactive_layer.setVisible(true);
  //interactive_tile_layer.setVisible(true);
}

function hideOverlays() {
  ai_layer.setVisible(false);
  aois_layer.setVisible(false);
  interactive_layer.setVisible(false);

  //ai_tile_layer.setVisible(false);
  //interactive_tile_layer.setVisible(false);  
}

function showTools() {
  tool_modify.setActive(true);
  tool_select.setActive(true);

  tool_polygon.getOverlay().setVisible(true);

  div_tools.style.display = "flex";
}

function hideTools() {
  tool_modify.setActive(false);
  tool_select.setActive(false);

  tool_polygon.getOverlay().setVisible(false);

  div_tools.style.display = "none";
}

function showToolMagic() {
  btn_magic.style.display = "flex";
}

function hideToolsMagic() {
  btn_magic.style.display = "none";
}

function showSupportTools() {
  onCommunicationUpdateRequired();

  showLocationButton();
  showCommunicationButton();

  if (isExportAllowed()) {
    showDownloadButton();
  }

  if (isAoisAllowed()) {
    showAoisButton();
    //enableAoisButton();
  }

  showVerificationButton();

  showOrthogonalizeButton();
  showCircularizeButton();

  showStatsButton();
}

function hideSupportTools() {
  hideLocationButton();
  hideCommunicationButton();
  hideCommunicationApp();
  hideDownloadButton();
  hideAoisButton();
  hideStatsButton();
  hideVerificationButton();
  hideVerificationApp();
}

function showLocationButton() {
  let user_data = getUserData();
  if (user_data.last_state != null && user_data.last_state.length > 0) {
    enableLocationButton();
  }
  div_location_button.style.display = "flex";
}

function hideLocationButton() {
  div_location_button.style.display = "none";
}

function showAoisButton() {
  div_aois_button.style.display = "flex";
}

function hideAoisButton() {
  div_aois_button.style.display = "none";
}

function showVerificationButton() {
  btn_verification.style.display = "flex";
}

function hideVerificationButton() {
  btn_verification.style.display = "none";
}

function showCommunicationButton() {
  div_communication_button.style.display = "flex";
}

function hideCommunicationButton() {
  div_communication_button.style.display = "none";
}

function showStatsButton() {
  document.getElementById("btn_stats").style.display = 'flex';
}

function hideStatsButton() {
  document.getElementById("btn_stats").style.display = 'none';
}

function showDownloadButton() {
  document.getElementById("btn_download").style.display = 'flex';
}

function hideDownloadButton() {
  document.getElementById("btn_download").style.display = 'none';
}

function toggleMode() {
  if (isVerificationEnabled()) {
    updateVerificationState(StateVerification.STATE_VERIFICATION_DISABLED);
  } else {
    if (is_viewer() == false) {
      if (isAuthenticated() == false) {
        updateVerificationState(StateVerification.STATE_VERIFICATION_WAIT_FOR_SIGNIN);
      } else {
        updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
      }
    }
  }
}

function toggleBevOrtho() {
  if (isUserLoggedIn()) {
    if (bev_ortho_layer.getVisible()) {
      bev_ortho_layer.setVisible(false);
    } else {
      bev_ortho_layer.setVisible(true);
    }
  }
}

function toggleCirOrtho() {
  if (isUserLoggedIn()) {
    if (austria_cir_ortho_layer.getVisible()) {
      austria_cir_ortho_layer.setVisible(false);
    } else {
      austria_cir_ortho_layer.setVisible(true);
    }
  }
}

function toggleBevBuildings() {
  if (isUserLoggedIn()) {
    if (bev_buildings_layer.getVisible()) {
      bev_buildings_layer.setVisible(false);
    } else {
      bev_buildings_layer.setVisible(true);
    }
  }
}

function toggleMagicTool() {
  if (getActiveTool() == "magic") {
    disableToolMagic();
    setActiveTool(null);
  } else {
    enableToolMagic();
    setActiveTool("magic");
  }
}

function togglePolygonTool() {
  if (getActiveTool() == "polygon") {
    disableToolPolygon();
    setActiveTool(null);
  } else {
    enableToolPolygon();
    setActiveTool("polygon");
  }
}

function toggleAIPredictions() {
  if (ai_layer.getVisible()) {
    disableToolAuto();
  } else {
    enableToolAuto();
  }
}

// ---
// Event listener for login/logout
// ---

registerOnLoginEvent(function () {

  setupTask(map, onTaskSetup);

  ortho_layer.getSource().refresh();
  interactive_source.refresh();
  ai_source.refresh();

  if (app_data.getStateVerification() == StateVerification.STATE_VERIFICATION_WAIT_FOR_SIGNIN) {
    updateVerificationState(StateVerification.STATE_VERIFICATION_ENABLED);
  }
})

registerOnLogoutEvent(function () {

  removeLayer(map, "task-border");
  removeLayer(map, "task-clip");

  ortho_layer.getSource().refresh();
  interactive_source.refresh();
  ai_source.refresh();

  if (isVerificationEnabled()) {
    updateVerificationState(StateVerification.STATE_VERIFICATION_WAIT_FOR_SIGNIN);
  }
})

// ---
// Event listener for comments
// ---

registerOnComment(function (comment) {
  feature_comment(comment, true);
})

// ---
// Event listener for communications
// ---

registerOnCommentsLoaded(function (comments) {
  var div_communication_span = document.getElementById("div_communication_button_span");
  var span_communication_button = document.getElementById("span_communication_button");

  if (comments.length > 0) {
    div_communication_span.style.display = "flex";
    span_communication_button.innerText = comments.length;
  } else {
    div_communication_span.style.display = "none";
  }
})


registerOnCommentSelected(function (comment) {
  console.log(comment);

  tool_select.getFeatures().clear();
  set_selected_polygon_to_url(comment.feature_index);

  selectGivenFeature();

  // TODO: animate only if feature is out of middle of screen (?)
  animateToExtent(comment.extent);
})

registerOnCommentResolved(function (feature_id, updated_comments) {
  var feature = interactive_source.getFeatureById(feature_id);
  if (feature != null) {
    if (feature.get("comment") != null || feature.get("comment_count") > 0) {
      feature.set("comment", null);
      feature.set("comment_count", 0);
    }

    // TODO: check if feature should be approved automatically after resolving the comment

    tool_select.getFeatures().clear();
    hideActionButtons();
    set_selected_polygon_to_url(null);
  }
})

// ---
// Event listener for verification app  
// ---

registerOnFeatureNavigated(function (data) {
  console.log("feature navigated");
  console.log(data.index);
  console.log(data.extent)

  tool_select.getFeatures().clear();
  set_selected_polygon_to_url(data.index);

  selectGivenFeature();
  onSelectedFeatureChanged(data.index);

  // TODO: animate only if feature is out of middle of screen (?)
  animateToExtent(data.extent);
})

// ---
// Event listener for tools and mode
// ---

document.getElementById('btn_mode').addEventListener('click', function (evt) {
  toggleMode();
});

document.getElementById('btn_magic').addEventListener('click', function (evt) {
  toggleMagicTool();
});

document.getElementById('btn_polygon').addEventListener('click', function (evt) {
  togglePolygonTool();
});

document.getElementById('btn_auto').addEventListener('click', function (evt) {
  toggleAIPredictions();
});


// ---
// Event listener for action buttons
// ---

document.getElementById('btn_approve').addEventListener('click', function (evt) {
  feature_approve();
});

document.getElementById('btn_ortho').addEventListener('click', function (evt) {
  feature_regularize("ortho");
});

document.getElementById('btn_circle').addEventListener('click', function (evt) {
  feature_regularize("circle");
});

document.getElementById('btn_delete').addEventListener('click', function (evt) {
  feature_delete();
});

document.getElementById('btn_comment').addEventListener('click', function (evt) {
  showCommentApp();
});


// ---
// Event listener for support buttons
// ---

document.getElementById('btn_download').addEventListener('click', function (evt) {
  toggleDownload();
})

document.getElementById('btn_aois').addEventListener('click', function (evt) {
  toggleAois();
})

document.getElementById('btn_aois_edit').addEventListener('click', function (evt) {
  enableAoisEditing();
})

document.getElementById('btn_aois_cancel').addEventListener('click', function (evt) {
  disableAoisEditing();
})

document.getElementById('btn_stats').addEventListener('click', function (evt) {
  toggleStats();
})

document.getElementById('btn_verification').addEventListener('click', function (evt) {
  toggleVerificationApp();
})

document.getElementById('btn_communication').addEventListener('click', function (evt) {
  toggleCommunicationApp();
})

document.getElementById('btn_location').addEventListener('click', function (evt) {
  toggleLocation();
});

document.getElementById('btn_location_cancel').addEventListener('click', function (evt) {
  reset_user_state();
  disableLocationButton();
});


// ---
// Event listener for map control buttons
// ---

document.getElementById('btn_fullscreen').addEventListener('click', function (evt) {
  toggleFullScreen();
});

document.getElementById('btn_zoomin').addEventListener('click', function (evt) {
  zoomTo(+1);
});

document.getElementById('btn_zoomout').addEventListener('click', function (evt) {
  zoomTo(-1);
});

document.getElementById('btn_layers').addEventListener('click', function (evt) {
  toggleLayers();
})

// ---
// Download options
// ---

document.getElementById("downloadVerifiedPolygons_crs_4326").addEventListener('click', function (evt) {

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/download`;
  const url_verified = api_endpoint + "?source=verified&type=polygon&crs=EPSG:4326";

  const filename = `${get_project_name()}_epsg_4326.geojson`

  downloadURI(url_verified, filename);
})

document.getElementById("downloadVerifiedPolygons_crs_31256").addEventListener('click', function (evt) {

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/download`;
  const url_verified = api_endpoint + "?source=verified&type=polygon&crs=EPSG:31256";

  const filename = `${get_project_name()}_epsg_31256.geojson`

  downloadURI(url_verified, filename);
})

document.getElementById("downloadAIPolygons").addEventListener('click', function (evt) {

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/download`;
  const url_ai = api_endpoint + "?source=ai&type=polygon";

  const filename = `${get_project_name()}_epsg_4326_unverified.geojson`

  downloadURI(url_ai, filename);
})

// TODO:
// - run performance tests
//   - simulate heavy use
//   - simulate clicks throughout the area

function downloadURI(url, name) {

  url += `&project=${get_project_name()}`;

  const task_id = get_task_id();
  if (task_id != null) {
    url += `&task=${task_id}`;
  }

  // Work around to download files via simulating clicking a link

  const options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
  }

  fetch(url, options)
    .then((response) => {

      if (response.status === 200) {
        response.blob().then(data => {
          hideInfoText();
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = name;
          a.click();
          a.remove();
        })
          .catch((e) => {
            console.log(e)
            console.log("network failure, cannot communicate with backend.")
            showInfoText("Connection failure, please retry ...");
          })
      }
      else if (response.status === 422) {
        console.log("Authentication failure (422)")
        onSessionExpired();
      } else if (response.status === 401) {
        console.log("Authentication failure (401)")
      }
      else {
        console.log("response failure")
        console.log(response)

        showInfoText("Connection failure, please retry ...");
      }
    }
    )
    .catch((e) => {
      console.log(e)
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      console.log("network failure, cannot communicate with backend.")

      showInfoText("Connection failure, please retry ...");
    })

}

// ---
// Map control functions
// ---

// Zoom in/out

function zoomTo(delta, duration = 500) {

  const view = map.getView();

  if (!view) {
    // the map does not have a view, so we can't act
    // upon it
    return;
  }

  const currentZoom = view.getZoom();

  if (currentZoom !== undefined) {
    const newZoom = view.getConstrainedZoom(currentZoom + delta);
    if (duration > 0) {
      if (view.getAnimating()) {
        view.cancelAnimations();
      }
      view.animate({
        zoom: newZoom,
        duration: duration,
        easing: inAndOut,
      });
    } else {
      view.setZoom(newZoom);
    }
  }
}

// Fullscren handling

function onFullScreenChange() {
  if (document.fullscreenElement) {
    document.getElementById("icon_maximize").setAttribute("hidden", "hidden");
    document.getElementById("icon_minimize").removeAttribute("hidden");
    document.getElementById("btn_fullscreen").title = "Exit full screen";
  } else {
    document.getElementById("icon_maximize").removeAttribute("hidden");
    document.getElementById("icon_minimize").setAttribute("hidden", "hidden");
    document.getElementById("btn_fullscreen").title = "Full screen";
  }
}

// Listen to fullscreen change event

document.documentElement.addEventListener("fullscreenchange", function (evt) {
  onFullScreenChange();
})

function toggleFullScreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    document.documentElement.requestFullscreen();
  }
}

// Store/load location

function toggleLocation() {
  if (btn_location.value == "on") {
    var user_data = getUserData();
    window.location.href = user_data.last_state;
    reset_user_state();
    disableLocationButton();
  } else {
    save_current_state();
    enableLocationButton();
  }
}

function enableLocationButton() {
  btn_location.style.background = "#93c47dff";
  btn_location.value = "on";
  btn_location.title = "Go to saved location";

  btn_location_cancel.style.display = "flex";
}

function disableLocationButton() {
  btn_location.style.background = "#eeeeeeff";
  btn_location.value = "off";
  btn_location.title = "Save current location to come back here later";

  btn_location_cancel.style.display = "none";
}

// Show/hide layer panel

function toggleLayers() {
  let layer_div = document.getElementById("div_layers");
  if (layer_div.getAttribute("hidden") == null) {
    layer_div.setAttribute("hidden", "hidden");
  } else {
    layer_div.removeAttribute("hidden");
  }
}

// Show/hide stats panel

function toggleStats() {

  var statistics_area = document.getElementById("div_statistics_area");
  statistics_area.style.display = "flex";
  statistics_area.style.pointerEvents = "all";

}

// Show/hide download panel

function toggleDownload() {

  let div = document.getElementById("div_download");

  if (div.getAttribute("hidden") == null) {
    div.setAttribute("hidden", "hidden");
    btn_download.style.background = "#eeeeeeff";
  } else {
    div.removeAttribute("hidden");
    btn_download.style.background = "#93c47dff";
  }
}

// Toggle AOIs feature

function toggleAois() {

  if (btn_aois.value == "on") {
    disableAoisEditing();
    disableAoisButton();
  } else {
    disableAoisEditing();
    enableAoisButton();
  }
}

function enableAoisButton() {
  btn_aois.style.background = "#93c47dff";
  btn_aois.value = "on";
  btn_aois.title = "Show areas of interest";

  btn_aois_edit.style.display = "flex";
  btn_aois_cancel.style.display = "none";

  aois_layer.setVisible(true);
}

function disableAoisButton() {
  btn_aois.style.background = "#eeeeeeff";
  btn_aois.value = "off";
  btn_aois.title = "Hide areas of interest";

  btn_aois_edit.style.display = "none";
  btn_aois_cancel.style.display = "none";

  aois_layer.setVisible(false);
}

function enableAoisEditing() {
  btn_aois_edit.value = "on";

  btn_aois_edit.style.display = "none";
  btn_aois_cancel.style.display = "flex";

  hideToolsMagic();
  enableToolPolygon();
  setActiveTool("polygon");
}

function disableAoisEditing() {
  btn_aois_edit.value = "off";

  btn_aois_edit.style.display = "flex";
  btn_aois_cancel.style.display = "none";

  tool_select.getFeatures().clear();

  showToolMagic();
}

// ---
// Action buttion functions
// ---

// Show/hide actions

export function showActionButtons() {
  div_actions.style.display = "flex";
}

export function hideActionButtons() {
  div_actions.style.display = "none";
}

// ---
// Tools
// ---

var tool_active = null;

var tool_polygon = null;
var tool_aois = null;
var tool_snap = null;
var tool_snap_guide = null;
var tool_copy_paste = null;
var tool_select = null;
var tool_dragbox = null;
var tool_modify = null;
var tool_translate = null;
var attribution = null;

function setActiveTool(tool) {
  tool_active = tool;
}

function getActiveTool() {
  return tool_active;
}

function enableToolMagic() {
  if (isApplicationStarting() == false) {
    disableToolPolygon();
  }

  btn_magic.style.background = "#93c47dff";
  btn_magic.value = "on";
}

function disableToolMagic() {
  feature_cancel();

  tool_select.style_ = STYLE_SELECT_TOOL;

  btn_magic.style.background = "#eeeeeeff";
  btn_magic.value = "off";
}

function enableToolPolygon() {
  disableToolMagic();

  btn_polygon.style.background = "#93c47dff";
  btn_polygon.value = "on";

  tool_polygon.setActive(true);
}

function disableToolPolygon() {
  feature_cancel();

  btn_polygon.style.background = "#eeeeeeff";
  btn_polygon.value = "off";

  tool_polygon.setActive(false);
}

function enableToolAuto() {
  ai_source.refresh();
  ai_layer.setVisible(true);
  //ai_tile_layer.setVisible(true);

  btn_auto.style.background = "#93c47dff";
  btn_auto.value = "on";
}

function disableToolAuto() {
  ai_layer.setVisible(false);
  //ai_tile_layer.setVisible(false);

  btn_auto.style.background = "#eeeeeeff";
  btn_auto.value = "off";
}

// ---
// Interactions
// ---

function isPolygonDrawingStarted() {
  return tool_polygon.sketchFeature_ != null;
}

function setupToolPolygon() {

  const layers = [
    interactive_layer,
    ai_layer,
  ]

  tool_polygon = new DrawCustom({
    //source: interactive_source,
    //features: tool_select.getFeatures(),
    type: "Polygon",
    condition: function (evt) {

      // Check if feature exists at current location 

      let has_feature_at_pxl = map.hasFeatureAtPixel(
        evt.pixel,
        {
          layerFilter: function (layer) {
            return (layers.includes(layer) && layer.getVisible() && !isAoisEditable()) ||
              (layer === aois_layer && isAoisEditable());
          }
        }
      )

      let taskingRequirementsFulfilled = isTaskingActivated() &&
        (isCoordinateWithinTask(evt.coordinate) ||
          isPolygonDrawingStarted());

      // Condition to start drawing foreground objects

      let foregroundCondition = primaryAction(evt) &&
        (has_feature_at_pxl == false || isPolygonDrawingStarted()) &&
        tool_select.getFeatures().getLength() == 0;

      // Condition to start drawing holes

      let backgroundCondition = evt.originalEvent.button == 2 &&
        has_feature_at_pxl == true && isPolygonDrawingStarted() == false &&
        tool_select.getFeatures().getLength() == 0;

      return noModifierKeys(evt) &&
        isVerificationAllowed() &&
        (foregroundCondition || backgroundCondition) &&
        (isTaskingActivated() == false || taskingRequirementsFulfilled);
    },
    freehandCondition: function () {
      return false;
    },
    style: STYLE_POLYGON_TOOL,
  });

  var feature_to_draw_hole = null;

  tool_polygon.on('drawstart', function (evt) {

    // Disable modify and selct interaction during drawing 

    tool_modify.setActive(false);
    tool_select.setActive(false);

    if (tool_polygon.init_button == 2) {

      // Right mouse button clicked, draw a hole

      var coordinate = evt.feature.getGeometry().getFirstCoordinate();

      feature_to_draw_hole = map.forEachFeatureAtPixel(
        map.getPixelFromCoordinate(coordinate),
        function (feature) {
          if ('Polygon' == feature.getGeometry().getType()) {
            return feature;
          }
        }
      );
    }

    showActionButtons();
  })

  tool_polygon.on('drawend', function (evt) {

    if (feature_to_draw_hole != null) {

      // Drawing has been started with right click, drawing hole

      var hole_coords = evt.feature.getGeometry().getCoordinates()[0];
      feature_to_draw_hole.getGeometry().appendLinearRing(new LinearRing(hole_coords));

      tool_polygon.source_ = null;

      //tool_polygon.features_ = null;
      tool_select.getFeatures().push(feature_to_draw_hole);

      feature_to_draw_hole = null;

    } else {

      evt.feature.set("source", "manual");

      if (btn_aois_edit.value == "on") {
        tool_polygon.source_ = aois_source;
      } else {
        tool_polygon.source_ = interactive_source;
      }

      //tool_polygon.features_ = tool_select.getFeatures();
      tool_select.getFeatures().push(evt.feature);

      console.log("push new feature to selected ones")

    }

    tool_modify.setActive(true);
  })

  tool_polygon.on('drawabort', function (evt) {
    feature_to_draw_hole = null
  })

  tool_polygon.setActive(false);
  map.addInteraction(tool_polygon);
}

function setupToolDragBox() {
  tool_dragbox = new DragBox({
    condition: function (evt) {
      return platformModifierKeyOnly(evt) && isVerificationAllowed();
    },
  });

  // clear selection when drawing a new box and when clicking on the map
  tool_dragbox.on('boxstart', function () {
    feature_cancel();
  });

  tool_dragbox.on('boxend', function () {
    const boxExtent = tool_dragbox.getGeometry().getExtent();
    console.log(boxExtent);

    // if the extent crosses the antimeridian process each world separately
    const worldExtent = map.getView().getProjection().getExtent();
    const worldWidth = getWidth(worldExtent);
    const startWorld = Math.floor((boxExtent[0] - worldExtent[0]) / worldWidth);
    const endWorld = Math.floor((boxExtent[2] - worldExtent[0]) / worldWidth);

    for (let world = startWorld; world <= endWorld; ++world) {
      const left = Math.max(boxExtent[0] - world * worldWidth, worldExtent[0]);
      const right = Math.min(boxExtent[2] - world * worldWidth, worldExtent[2]);
      const extent = [left, boxExtent[1], right, boxExtent[3]];

      if (ai_layer.getVisible() && !isAoisEditable()) {
        const boxFeatures = ai_source
          .getFeaturesInExtent(extent)
          .filter(
            (feature) =>
              !tool_select.getFeatures().getArray().includes(feature) &&
              feature.getGeometry().intersectsExtent(extent)
          );

        // features that intersect the box geometry are added to the
        // collection of selected features

        tool_select.getFeatures().extend(boxFeatures);
      }

      if (interactive_layer.getVisible() && !isAoisEditable()) {
        const boxFeatures = interactive_source
          .getFeaturesInExtent(extent)
          .filter(
            (feature) =>
              !tool_select.getFeatures().getArray().includes(feature) &&
              feature.getGeometry().intersectsExtent(extent)
          );

        // features that intersect the box geometry are added to the
        // collection of selected features

        tool_select.getFeatures().extend(boxFeatures);
      }

      if (aois_layer.getVisible() && isAoisEditable()) {
        const boxFeatures = aois_source
          .getFeaturesInExtent(extent)
          .filter(
            (feature) =>
              !tool_select.getFeatures().getArray().includes(feature) &&
              feature.getGeometry().intersectsExtent(extent)
          );

        // features that intersect the box geometry are added to the
        // collection of selected features

        tool_select.getFeatures().extend(boxFeatures);
      }
    }
    handleSelect(tool_select.getFeatures().getArray());
  });

  map.addInteraction(tool_dragbox);
}

function isAoisEditable() {
  return btn_aois.value == "on" && btn_aois_edit.value == "on";
}

function setupToolSelect() {

  // Feature selection

  const layers = [
    ai_layer,
    interactive_layer,
  ]

  tool_select = new Select({
    wrapX: false,
    hitTolerance: 0,
    condition: function (evt) {
      // TODO: handle feature outlines, s.t. do not select if click is on feature outline
      return click(evt) && isVerificationAllowed();
    },
    filter: function (feature, layer) {
      return (layers.includes(layer) && layer.getVisible() && !isAoisEditable()) ||
        (layer === aois_layer && isAoisEditable());
    },
    style: STYLE_SELECT_TOOL,
  });

  tool_select.on('select', function (evt) {
    console.log(evt)
    handleSelect(evt.selected);
  })

  map.addInteraction(tool_select);
}

function setupToolModify() {
  tool_modify = new ModifyCustom({
    features: tool_select.getFeatures(),
    //pixelTolerance: 5,
    hitDetection: true,
  });

  tool_modify.on("modifystart", function (evt) {
    console.log("modifystart");
  })

  tool_modify.on("modifyend", function (evt) {
    console.log("modifyend");
  })

  map.addInteraction(tool_modify);
}

function setupToolSnap() {
  tool_snap = new Snap({
    source: interactive_source,
    pixelTolerance: 5,
  });

  map.addInteraction(tool_snap);
}

function setupToolSnapGuide() {
  tool_snap_guide = new SnapGuide({
    vectorClass: VectorImage,
  });

  tool_snap_guide.setDrawInteraction(tool_polygon);
  tool_snap_guide.setModifyInteraction(tool_modify);
  map.addInteraction(tool_snap_guide);
}

function setupToolCopyPaste() {
  tool_copy_paste = new CopyPaste({
    features: tool_select.getFeatures(),
  });

  tool_copy_paste.on('cut', function (e) {
    // We don't really cut. On cut it will be pasted too.
  });

  tool_copy_paste.on('paste', function (evt) {

    // First, reset selected features

    tool_select.getFeatures().clear();

    // Second, copy all given features

    evt.features.forEach(function (feature) {

      // Copy feature by encoding/decoding via geojson

      var format = new GeoJSON();
      var geojson = format.writeFeatureObject(
        feature, {
        'dataProjection': 'EPSG:4326',
        'featureProjection': 'EPSG:3857',
      });

      var f = format.readFeature(
        geojson,
        {
          dataProjection: 'EPSG:4326',
          featureProjection: map.getView().getProjection(),
        }
      );

      // Ensure that no id is set

      f.setId(undefined);

      // Add copied feature to source and selection

      if (isAoisEditable()) {
        aois_source.addFeature(f);
      } else {
        interactive_source.addFeature(f);
      }

      tool_select.getFeatures().push(f);
      handleSelect(tool_select.getFeatures());
    });
  });

  map.addInteraction(tool_copy_paste);
}

function setupToolTranslate() {
  tool_translate = new TranslateCustom({
    features: tool_select.getFeatures(),
  });

  map.addInteraction(tool_translate);
}

function setupAttribution() {
  attribution = new Attribution({
    collapsible: false,
    collapsed: true,
  });

  map.addControl(attribution)

  window.addEventListener('resize', function (evt) {
    const small = map.getSize()[0] < 600;
    if (small && attribution.getCollapsed() == false)
      attribution.setCollapsed(true);
  });
}

function setupTools() {
  setupToolPolygon();
  setupToolSelect();
  setupToolTranslate();
  setupToolDragBox();
  setupToolModify();
  setupToolSnap();
  setupToolSnapGuide();
  setupToolCopyPaste();
  setupAttribution();
}


function showOrthogonalizeButton() {
  document.getElementById("btn_ortho").style.display = 'flex';
}

function hideOrthogonalizeButton() {
  document.getElementById("btn_ortho").style.display = 'none';
}

function showCircularizeButton() {
  document.getElementById("btn_circle").style.display = 'flex';
}

function hideCircularizeButton() {
  document.getElementById("btn_circle").style.display = 'none';
}

setupTools();

// ---
// Startup
// ---

enableToolPolygon();
enableToolAuto();
setActiveTool("polygon");


function updateVerificationState(state) {

  let curr_state = app_data.getStateVerification();

  console.log(`Change state from ${curr_state} to ${state}`);

  app_data.setStateVerification(state);

  switch (state) {
    case StateVerification.STATE_VERIFICATION_DISABLED:
      stateVerificationUIDisabled();
      break;
    case StateVerification.STATE_VERIFICATION_WAIT_FOR_SIGNIN:
      stateVerificationUIWaitForSignIn();
      break;
    case StateVerification.STATE_VERIFICATION_ENABLED:
      stateVerificationUIEnabled();
      break;
    case StateVerification.STATE_VERIFICATION_READY:
      stateVerificationUIReady();
      break;
    case StateVerification.STATE_VERIFICATION_BUSY:
      stateVerificationUIBusy();
      break;
    case StateVerification.STATE_VERIFICATION_UNKNOWN:
    default:
      break;
  }
}


function stateVerificationUIDisabled() {

  console.log("hide elements")

  document.getElementById("icon_ortho").removeAttribute("hidden");
  document.getElementById("icon_verified").setAttribute("hidden", "hidden");

  btn_mode.style.background = "#eeeeeeff";
  btn_mode.style.display = "inline-flex";

  hideOverlays();
  hideActionButtons();
  hideTools();
}

function stateVerificationUIWaitForSignIn() {

  document.getElementById("icon_verified").removeAttribute("hidden");
  document.getElementById("icon_ortho").setAttribute("hidden", "hidden");

  btn_mode.style.background = "#93c47dff";
  btn_mode.style.display = "inline-flex";

  const project_name = get_project_name();
  showOverlays();
  hideActionButtons();
  hideTools();

  hideSupportTools()

  progress_bar.setVisible(false);

  showInfoText("Sign in to edit the map");
}


function stateVerificationUIEnabled() {

  document.getElementById("icon_verified").removeAttribute("hidden");
  document.getElementById("icon_ortho").setAttribute("hidden", "hidden");

  btn_mode.style.background = "#93c47dff";
  btn_mode.style.display = "inline-flex";

  // --- 
  // Reload layers after signing in to ensure all data is loaded
  // ---

  showOverlays();
  hideActionButtons();
  hideTools();

  showSupportTools()

  if (map.getView().getZoom() <= 17) {
    showInfoText("Zoom in to edit features");
  } else {
    updateVerificationState(StateVerification.STATE_VERIFICATION_READY);
  }
}


function stateVerificationUIReady() {

  hideInfoText();

  //interactive_source.refresh();
  //ai_source.refresh(); 

  selectGivenFeature();
  showTools();
}


function stateVerificationUIBusy() {

  document.getElementById("icon_ortho").removeAttribute("hidden");
  document.getElementById("icon_verified").setAttribute("hidden", "hidden");

  btn_mode.style.background = "#eeeeeeff";
  btn_mode.style.display = "none"

  hideActionButtons();
  hideTools();
}


function isVerificationAllowed() {
  return app_data.getStateVerification() == StateVerification.STATE_VERIFICATION_READY;
}


function isVerificationEnabled() {
  switch (app_data.getStateVerification()) {
    case StateVerification.STATE_VERIFICATION_ENABLED:
    case StateVerification.STATE_VERIFICATION_READY:
    case StateVerification.STATE_VERIFICATION_BUSY:
      return true;
    default:
      return false;
  }
}


function isApplicationStarting() {
  return app_data.getStateVerification() == StateVerification.STATE_VERIFICATION_STARTING
}

hideTools();
