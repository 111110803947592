
import GeoJSON from 'ol/format/GeoJSON.js';
import * as turf from '@turf/turf'

import { setupTaskClipLayer, setupTaskBorderLayer } from "./layer";
import { CONFIG_MAP_PROJECTION } from "./config";
import { get_task_id, get_api_url, get_project_name, get_view_from_url } from "./url";
import { getAuthData } from '../src/apps/user_app';


function get_inverse_feature(task_feature, buffer=null) {
    
    var format = new GeoJSON();

    var task_geojson = format.writeFeatureObject(task_feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: CONFIG_MAP_PROJECTION,
        decimals: 8
    });

    if (buffer != null) {
        task_geojson = turf.buffer(
            task_geojson, 
            buffer, {
                'units': 'kilometers'
            }
        )
    }

    var world_geojson = turf.polygon([[
        [-180, 90], 
        [180, 90], 
        [180, -90], 
        [-180, -90], 
        [-180, 90]
    ]])

    var inverse_geojson = turf.difference(
        world_geojson, 
        task_geojson
    );

    var inverse_feature = format.readFeature(
        inverse_geojson, {
            dataProjection: 'EPSG:4326',
            featureProjection: CONFIG_MAP_PROJECTION,
        }
    );

    return inverse_feature
}

export async function setupTask(map, onTaskSetup) {

    const task_id = get_task_id();

    if (task_id != null) {
        
        const api_url = get_api_url();
        const api_endpoint = `${api_url}/task`;        
        const url = `${api_endpoint}?task=${task_id}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getAuthData(),
            },
        }).catch((e) => {
            console.log(e);
        });
    
        if (response) {

            // TODO: error handling
            // if (response.status === 200) ....

            console.log("Received task esponse");
            console.log(response);

            response.json().then(data => {

                // Setup task clip & border layer

                const task_clip_layer = setupTaskClipLayer();
                map.addLayer(task_clip_layer);

                const task_border_layer = setupTaskBorderLayer();
                map.addLayer(task_border_layer);

                // Load task geometry

                var task_features = new GeoJSON().readFeatures(
                    data["data"], {
                        dataProjection: 'EPSG:4326',
                        featureProjection: CONFIG_MAP_PROJECTION,
                    }
                );

                var task_feature = task_features[0];

                // Get inverse features for clipping and border

                var clip_feature = get_inverse_feature(task_feature, 0.50);
                var border_feature = get_inverse_feature(task_feature);
                
                // Visualize inverse features 

                task_clip_layer.getSource().addFeature(clip_feature)
                task_border_layer.getSource().addFeature(border_feature)
                                
                // Zoom to task area if view is not set in url

                if (get_view_from_url() == null) {
                    map.getView().fit(task_feature.getGeometry(), {
                        padding: [50, 50, 50, 50]
                    });
                }

                onTaskSetup(task_feature.getGeometry());

            }).catch((e) => {
                console.log(e);
            });      
        }
    }
}