
import VectorLayer from 'ol/layer/Vector.js';
import TileLayer from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector.js';
import { TileDebug } from 'ol/source.js';

import VectorTileLayer from 'ol/layer/VectorTile.js';
import TileWMS from 'ol/source/TileWMS.js';
import VectorTile from 'ol/source/VectorTile.js';
import {createXYZ} from 'ol/tilegrid';
import MVT from 'ol/format/MVT.js';

import {OSM} from 'ol/source.js';
import XYZ from 'ol/source/XYZ.js';

import { STYLE_AOIS, STYLE_BUILDINGS_APPROVED, STYLE_BUILDINGS_COMMENTED } from './style.js'
import { STYLE_BUILDINGS_AI } from './style.js'
import { STYLE_TASK_CLIP_AREA } from './style.js'
import { STYLE_TASK_BORDER_AREA } from './style.js'
import { get_project_name, get_tile_server_url, get_wfs_server_url } from './url.js';
import { load_project_info } from './project.js';

const PRELOAD_LEVEL = Infinity;

import GeoJSON from 'ol/format/GeoJSON.js';
import {bbox as bboxStrategy} from 'ol/loadingstrategy.js';
import { getAuthData, isUserLoggedIn } from '../src/apps/user_app.jsx';


function styleApprovedPolygons(feature, resolution) {
    if (feature.get("comment") != null || feature.get("comment_count") > 0) {
        return STYLE_BUILDINGS_COMMENTED;
    } else {
        return STYLE_BUILDINGS_APPROVED;
    }
}


export function setupInteractiveLayer(source) {
    return new VectorLayer({
        source: source,
        style: styleApprovedPolygons,
        title: 'Verified',
        type: "overlay",
        name: "interactive",
        visible: true,
        renderMode: "vector",
        minZoom: 17,
        updateWhileInteracting: false, // querying can get really slow if done while interacting
        updateWhileAnimating: false, // haven't seen much benefit in activating this but introduces some load on the database
      });
}


export function setupInteractiveTileLayer() {

    const project_name = get_project_name();
    const tile_server_url = get_tile_server_url();
    const tile_layer_url = `${tile_server_url}/${project_name}/verified/{z}/{x}/{y}.mvt`;

    return new VectorTileLayer({
        style: styleApprovedPolygons,
        title: 'Annotation (tiles)',
        declutter: true,
        source: new VectorTile({
            tileGrid: createXYZ({ maxZoom: 17 }),
            format: new MVT(),
            url: tile_layer_url,
            minZoom: 0,
            maxZoom: 17,
        }),
        minZoom: 0,
        maxZoom: 17,
        preload: PRELOAD_LEVEL,
    });
}

export function setupAILayer(source) {
    return new VectorLayer({
        source: source,
        style: STYLE_BUILDINGS_AI,
        title: 'Unverified',
        type: "overlay",
        name: "ai_assisted",
        visible: false,
        renderMode: "vector",
        minZoom: 17,
    });
}

export function setupAITileLayer() {

    const project_name = get_project_name();
    const tile_server_url = get_tile_server_url();
    const tile_layer_url = `${tile_server_url}/${project_name}/ai/{z}/{x}/{y}.mvt`;

    return new VectorTileLayer({
        style: STYLE_BUILDINGS_AI,
        title: 'Automatic (tiles)',
        declutter: true,
        source: new VectorTile({
            tileGrid: createXYZ({ maxZoom: 17 }),
            format: new MVT(),
            url: tile_layer_url,
            minZoom: 0,
            maxZoom: 17
        }),
        minZoom: 0,
        maxZoom: 17,
        preload: PRELOAD_LEVEL,
    });
}

export function setupAOIsLayer(source) {
    return new VectorLayer({
        source: source,
        style: STYLE_AOIS,
        title: 'Area of Interests',
        type: "overlay",
        name: "aois",
        visible: false,
        renderMode: "vector",
        minZoom: 13,
    });
}

export function setupWFSLayer(layer_name) {

    const wfs_server_url = get_wfs_server_url();

    const vectorSource = new VectorSource({
        format: new GeoJSON(),
        strategy: bboxStrategy,
        loader: function(extent, resolution, projection, success, failure) {
            const proj = projection.getCode();
            var wfs_url = wfs_server_url + '?service=WFS&' +
                'version=1.1.0&request=GetFeature&typename=' + layer_name + '&' +
                'outputFormat=application/json&srsname=EPSG:3857&' +
                'bbox=' +
                extent.join(',') +
                ',EPSG:3857';
            console.log(wfs_url);

            const xhr = new XMLHttpRequest();
            xhr.open('GET', wfs_url);
            const onError = function() {
              vectorSource.removeLoadedExtent(extent);
              failure();
            }

            xhr.onerror = onError;
            xhr.onload = function() {
              if (xhr.status == 200) {
                const features = vectorSource.getFormat().readFeatures(xhr.responseText);
                vectorSource.addFeatures(features);
                success(features);
              } else {
                onError();
              }
            }
            xhr.send();
        },
    });
    
    const vector = new VectorLayer({
        source: vectorSource,
        style: {
            'stroke-width': 1.25,
            'stroke-color': 'black',
            'fill-color': 'rgba(100,100,100,0.25)',
        },
        title: 'Buildings Kataster',
        type: "overlay",
        name: "buildings-kaster",
        visible: false,
        renderMode: "vector",
        minZoom: 17,
    });

    return vector;
}


export function setupCIRLayer() {

    const wfs_server_url = get_wfs_server_url();

    var wmts_layer = new TileLayer({
        title: "CIR Image",
        visible: false,
        source: new TileWMS({
          url: wfs_server_url,
          params: {'LAYERS': 'austria_ortho_cir_2021', 'TILED': true},
          transition: 0,
          interpolate: false,
        }),
      })

    return wmts_layer;
}


export function setupWMTSLayer(
    wmts_server_url, 
    wmts_layer_name, 
    wmts_layer_title,
) {
    var wmts_layer = new TileLayer({
        title: wmts_layer_title,
        visible: true,
        source: new TileWMS({
          url: wmts_server_url,
          params: {
            'LAYERS': wmts_layer_name, 
            'TILED': true,
          },
          transition: 0,
          interpolate: false,
        }),
        preload: PRELOAD_LEVEL,
      })

    return wmts_layer;
}



export function setupOSMLayer() {
    return new TileLayer({
        title: 'OpenStreetMap',        
        type: 'base',
        visible: false,
        source: new OSM(),
        preload: PRELOAD_LEVEL,
    });
}

function customOrthoTileLoader(tile, src) {

    // ---
    // This tile loader sets the 'Authorization' header
    // ---

    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.addEventListener('loadend', function (evt) {
        const data = this.response;
        if (data !== undefined) {
            tile.getImage().src = URL.createObjectURL(data);
        } else {
            tile.setState(TileState.ERROR);
        }
    });

    xhr.addEventListener('error', function () {
        tile.setState(TileState.ERROR);
    });

    xhr.open('GET', src);

    if (isUserLoggedIn()) {
        xhr.setRequestHeader("Authorization", getAuthData());
    }

    xhr.send();
}

export function setupOrthoLayer() {

    const project_name = get_project_name();
    const tile_server_url = get_tile_server_url();
    const tile_layer_url = `${tile_server_url}/${project_name}/ortho/{z}/{x}/{y}.png`;

    var ortho_layer = new TileLayer({
        title: 'Ortho Image',
        type: 'base',
        visible: true,
        source: new XYZ({
            minZoom: 5,
            maxZoom: 22,
            url: tile_layer_url,
            tileSize: [256, 256],
            interpolate: false,
            tileLoadFunction: customOrthoTileLoader,
        }),        
        preload: PRELOAD_LEVEL,
    });

    load_project_info(function(data) {
        if (data) {
            const attribution = data["attribution"]
            if (attribution != undefined) {
                if (attribution["imagery"] != undefined) {
                    ortho_layer.getSource().setAttributions([
                        attribution["imagery"]
                    ])
                }
            }
        }
    })

    return ortho_layer
}


export function setupBevOrthoLayer() {

    const tile_layer_url = 'https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg';

    var ortho_layer = new TileLayer({
        title: 'BEV Ortho',
        type: 'base',
        visible: false,
        source: new XYZ({
            attributions: 'Ortho imagery by basemap.at',
            minZoom: 5,
            maxZoom: 22,
            url: tile_layer_url,
            tileSize: [256, 256],
            interpolate: false,
        }),        
        preload: PRELOAD_LEVEL,
    });

    return ortho_layer
}

export function setupTileDebugLayer() {
    return new TileLayer({
        source: new TileDebug(),
    })
}


export function setupTaskClipLayer() {

    return new VectorLayer({
        source: new VectorSource(),
        style: STYLE_TASK_CLIP_AREA,
        title: "Task Clip",
        type: 'overlay',
        name: 'task-clip',
        visible: true, 
        renderMode: "vector",
    });
}


export function setupTaskBorderLayer() {

    return new VectorLayer({
        source: new VectorSource(),
        style: STYLE_TASK_BORDER_AREA,
        title: "Task Border",
        type: 'overlay',
        name: 'task-border',
        visible: true, 
        renderMode: "vector",
    });
}

