
import { sleep } from './retry.js';


export class ProgressBar {

    total_ = 0;
    current_ = 0;
    failed_ = 0;
    progress_div_ = null;
    progress_text_ = null;
    progress_bar_ = null;
    start_time_ = null;
    on_finish_ = null
  
    constructor() {
      this.progress_div_ = document.getElementById("div_progress");
      this.progress_text_ = document.getElementById("progress_text");
      this.progress_bar_ = document.getElementById("progress_bar");
    }

    setup(total, on_start, on_finish) {

      this.total_ = total;
      this.on_start = on_start;
      this.on_finish_ = on_finish;

      this.current_ = 0;
      this.failed_ = 0;
    
      this.start_time_ = performance.now();
  
      this.updateProgress();
    }
  
    isDone() {
      return this.progress() == 100;
    }

    isProgressing() {
      return this.isVisible() && !this.isDone();
    }

    isVisible() {
      return this.progress_div_.style.display == "grid";
    }

    // TODO: show background gray (same as comment box), on click on background, progressbar will be hidden if done

    setVisible(visible) {
      if (visible) {
        this.progress_div_.style.display = "grid";
      } else {
        this.progress_div_.style.display = "none";
      }

    }

    succeeded(step) {
      this.current_ += step;
      this.updateProgress();
    }
  
    failed(step) {
      this.failed_ += step;
      this.updateProgress();
    }

    progress() { 
      return Math.round(this.current_ / this.total_ * 100);
    }
  
    async updateProgress() {

      var progress = this.progress();

      var curr_time = performance.now();
      var duration_in_ms = curr_time - this.start_time_;

      if (this.total_ > 1 && duration_in_ms > 300 && progress <50) {
        this.on_start();
        this.setVisible(true);
      }

      if (this.isVisible()) {
  
        if (progress < 100) {
          this.progress_text_.innerHTML = `<b>Sending to server ... </b>`;
        } else {
          this.progress_text_.innerHTML = `<b>Updates sent</b>`;
        }

        this.progress_text_.innerHTML += `<br><br>`;
        this.progress_text_.innerHTML += `${progress}% (${this.current_} / ${this.total_})`;

        this.progress_bar_.style.width = `${progress}%`;
    
        if (this.failed_ > 0) {
          this.progress_text_.innerHTML += ` Failed: ${this.failed_}`;
        }
    
        if (progress == 100) {
          this.on_finish_();
        }
      }
    }
  }