import { get_api_url, get_project_name } from "./url";
import { getAuthData, isUserLoggedIn } from './../src/apps/user_app';


export async function load_project_info(on_receive_callback) {

    const api_url = get_api_url();
    const api_endpoint = `${api_url}/info`;
    const project = get_project_name();

    // ATTENTION: loading the project info is not properly handled with authorization.
    //            s.t. project info is not reloaded after logging in yet

    var _headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    if (isUserLoggedIn()) {       
        _headers['Authorization'] = getAuthData()
    }

    console.log(_headers);

    const request_url = `${api_endpoint}?project=${project}`;
    const request_params = {
        method: 'GET',
        headers: _headers,
    };

    fetch(request_url, request_params)
    .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    console.log("project info received")
                    console.log(data)
                    on_receive_callback(data.data)
                })
                .catch((e) => {
                    console.log(e)
                });
            } 
            console.log(response)
        }
    )
    .catch((e) => {
        console.log(e)
    });
}