export const CONFIG_PROJECT = "";
export const CONFIG_MAP_PROJECTION = "EPSG:3857";

//export const CONFIG_ENTRY_POINT = "https://www.n-vision.ai"
export const CONFIG_ENTRY_POINT = "https://bcknd.n-vision.ai"

export const CONFIG_API_URL = CONFIG_ENTRY_POINT + "/api/v1";
export const CONFIG_API_URL_DEV = "http://localhost:15000/api/v1";

export const CONFIG_TILE_SERVER_URL = CONFIG_ENTRY_POINT + "/tiles/v1";
export const CONFIG_TILE_SERVER_URL_EU_WEST = CONFIG_ENTRY_POINT + "/tiles/v1";
//export const CONFIG_TILE_SERVER_URL_EU_WEST = "https://www.n-vision.ai/tileseuwest/v1";
export const CONFIG_TILE_SERVER_URL_DEV = "http://localhost:15100/tiles/v1";

export const CONFIG_INFER_SERVER_URL = CONFIG_ENTRY_POINT + "/infer/v1";
export const CONFIG_INFER_SERVER_URL_DEV = "http://localhost:15200/infer/v1";

export const CONFIG_GEOMETRY_SERVER_URL = CONFIG_ENTRY_POINT + "/geometry/v1";
export const CONFIG_GEOMETRY_SERVER_URL_DEV = "http://localhost:15300/geometry/v1";

export const CONFIG_WFS_SERVER_URL = CONFIG_ENTRY_POINT + "/srv";
export const CONFIG_WFS_SERVER_URL_DEV = "http://localhost:5400/srv";

export const CONFIG_DEV_MODE = false;
