import './communication_table.scss';
import resolved_icon from '../../icons/resolved.png';

import React, { useEffect, useState, useRef } from 'react';

import { get_api_url } from '../../js/url';
import { getAuthData } from '../apps/user_app';


// ---
// Events which are triggered after item has been clicked
// ---

var on_selected_callback = [];

export function registerOnCommentSelected(func) {
    on_selected_callback.push(func);
}

export function onCommentSelectedEvent(data) {
    on_selected_callback.forEach(function (func) {
        func(data);
    })
}

// ---
// Events which are triggered after item has been resolved
// ---

var on_resolved_callback = [];

export function registerOnCommentResolved(func) {
    on_resolved_callback.push(func);
}

function onCommentResolvedEvent(feature_id, new_comments) {
    on_resolved_callback.forEach(function (func) {
        func(feature_id, new_comments);
    })
}

// ---
// Communication table
// ---

export function CommunicationTable({project, comments, selected_feature_id}) {

    const ref_selected = useRef();

    useEffect(() => {
        ref_selected.current?.scrollIntoViewIfNeeded({
            alignToTop: true,
            behavior: 'smooth',
          });
    }, [selected_feature_id])

    function handleItemClick(data) {
        onCommentSelectedEvent(data)
    }

    function handleResolveClick(evt, data) {
        evt.stopPropagation();

        const feature_id = data.feature_index;      
        sendResolved(feature_id);

        var updated_comments = []
        comments.data.forEach((feature) => {
            if (feature.feature_index != feature_id) {
                updated_comments.push(feature);
            }
        })

        const new_comments = JSON.parse(JSON.stringify(comments));
        new_comments.data = updated_comments;

        onCommentResolvedEvent(feature_id, new_comments);
    }

    function renderDateTime(date_time) {
        let formatter = new Intl.DateTimeFormat('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short' ,
            hour12: 'false',
        });

        let date = new Date(Date.parse(date_time));
        let formatted_date = date.toLocaleString()
        return (formatter.format(date))
    }

    function renderOlderComments(comments) {
        return (
            <div className='comment-sub'> {
                comments.map(function(comment) {
                    return (
                        <div key={comment.comment_id} className='div_communication_table_sub_item'>

                            <div className='comment-header align-right'>
                                <span className='comment-prop'>{comment.first_name}</span>
                                <span className='comment-prop'>{comment.last_name}</span>
                            </div>

                            <div className='align-right'>
                                <span className='comment-prop'>{renderDateTime(comment.timestamp)}</span>
                            </div>

                            <div className='align-right comment-text' >
                                <span>{comment.comment}</span>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        )
    }

    function renderComment(data) {
        return (
            <div key={data.feature_index} ref={data.feature_index == selected_feature_id ? ref_selected : null}
                 className={data.feature_index == selected_feature_id ? 'div_communication_table_item selected-item-color' : 'div_communication_table_item'}>

                <div className='comment-main' onClick={() => handleItemClick(data)}>
                    <div className='align-horizontally'>
                        <div>
                            <div className='comment-header'>
                                <span className='comment-prop'>{data.comments[0].first_name}</span>
                                <span className='comment-prop'>{data.comments[0].last_name}</span>
                            </div>
                            <div>
                                <span className='comment-prop'>{renderDateTime(data.comments[0].timestamp)}</span>
                            </div>
                        </div>
                        <div>
                            <button onClick={(evt) => handleResolveClick(evt, data)} className='resolve-button' title="Mark comment as resolved">
                                <img width="20px" height="20px" src={resolved_icon}/>
                            </button>
                        </div>
                    </div>
                    <div className='comment-text'>
                        <span>{data.comments[0].comment}</span>
                    </div>

                    {data.comments.length > 1 ? renderOlderComments(data.comments.slice(1)) : ""}

                </div>                
            </div>
        )
    }

    return (
        <div className='div_communication_table'> 
            {comments.data.map(function(data) {
                return renderComment(data)
            })}
        </div>
    );    
}

function sendResolved(feature_index) {

  const api_url = get_api_url();
  const api_endpoint = `${api_url}/comment/resolve`;    
  
  const body = {
    "feature_index": feature_index,    
  }

  fetch(api_endpoint, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthData(),
    },
    body: JSON.stringify(body)
  })
  .then((response) => {
    
    if (response.status === 200) {
      console.log("comment resolved successfully sent");
    } else if (response.status === 422) {
      console.log("error while loading user data (422)")
      onSessionExpired();
    } else if (response.status === 401) {
      console.log("error while loading user data (401)")
    } else {
      console.log("error while sending error while sending 'comment resolved'")
    }
  })
  .catch((e) => {
    console.log("cannot send 'comment resolved'");
    console.log(e);
  });
}
